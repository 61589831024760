<template>
    <error-read-contents :ga-event-label="this.gaEventLabel" id="js-error-reload" @load="setHeight"/>
</template>

<script>
    import * as documentElementSizeUtil from "../../util/documentElementSizeUtil.js";
    import ErrorReadContents from "../molecules/ErrorReadContents.vue";

    export default {
        name: "ErrorReload",
        components: {
            ErrorReadContents
        },
        props: {
            /**
             * リロード画面の高さ.
             * nullの場合は全画面サイズ（ヘッダー・フッターの高さを除く）になる
             */
            height: {
                type: Number,
                default: null,
                required: false
            },
            /**
             * リロードボタンを押した際に送信されるGAイベントラベル
             */
            gaEventLabel: {
                type: String,
                required: true
            }
        },
        mounted() {
            this.setHeight()
        },
        methods: {
            setHeightFullSize() {
                let loadingDiv = document.getElementById('js-error-reload');
                loadingDiv.style.margin = (documentElementSizeUtil.getMainContentClientHeight()-loadingDiv.offsetHeight)/2 + "px auto";
            },
            setPropsHeight(height) {
                let loadingDiv = document.getElementById('js-error-reload');
                loadingDiv.style.margin = height + "px auto";
            },
            setHeight() {
                if(this.height){
                    this.setPropsHeight(this.height);
                } else {
                    this.setHeightFullSize()
                }
            },
        },
    }
</script>