import pictlinkApi from '../interceptor/AxiosInterceptor';

import * as optimizedFlowAction from './OptimizedFlowAction'

import PaymentTrialViewModel from '../../view-model/PaymentTrialViewModel'

import * as urlUtil from '../../util/UrlUtil'
import PaymentOutputData from "../output-data/PaymentOutputData";
import PaymentViewModel from "../../view-model/PaymentViewModel";

/**
 * お試し可能かどうかのデータを取得する
 * ユーザ導線最適化(UFO)の情報もあわせて取得し、お試し不可の場合はこちらの理由を優先してセットする.
 */
export function getPaymentTrial() {
    return Promise.all([
        pictlinkApi.get(urlUtil.apiEndpointUtil.paymentTrialApiUrl()),
        // お試しの導線を表示する場所それぞれのUFOを取得する
        // 表示場所が増える場合はここに追加する
        // (ただしここの管理が増えるのは辛すぎるのでいずれはUFOの設計にテコ入れが入る予定)
        optimizedFlowAction.getOptimizedFlow('TRIAL_JUDGE'),
        optimizedFlowAction.getOptimizedFlow('PURI_GRID'),
        optimizedFlowAction.getOptimizedFlow('MY_PAGE'),
        optimizedFlowAction.getOptimizedFlow('PURIGET_LP')
    ]).then( ([paymentTrial, trialJudge, puriGetComplete, myPage, puriGet]) => {
        return new PaymentTrialViewModel(paymentTrial, trialJudge, puriGetComplete, myPage, puriGet);
    });
}

/**
 * ユーザの課金状態のデータを取得する
 */
export function getPayment() {
    return pictlinkApi.get(urlUtil.apiEndpointUtil.paymentApiUrl())
        .then(r => new PaymentOutputData(r))
        .then(r => {
            return new PaymentViewModel(r)
        })
}

