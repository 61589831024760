import Account from '../../view-model/enum/InquiryAccount'
function getDefaultState() {
    return {
        inputtedText: "",
        selectedCarrier: "",
        selectedSealId: "",
        inputtedSealIdSealNotOutputDetailText: "",
        inputtedSealId: [],
        selectedMachine: "",
        inputtedMachineText: "",
        inputtedShop: "",
        inputtedDate: "",
        checkedImage: "",
        selectedAccount: new Account(''),
        selectedPaymentKind: "",
        selectedPaymentPrice: "",
        inputtedMail: [],
        checkedHowToRegister: [],
        isValidatedMailList: true,
        isValidatedSealIdList: true,
    };
}
export const state = getDefaultState();

const getters = {
    inputtedText(state) {
        return state.inputtedText;
    },
    selectedCarrier(state) {
        return state.selectedCarrier;
    },
    selectedSealId(state) {
        return state.selectedSealId;
    },
    inputtedSealIdSealNotOutputDetailText(state) {
        return state.inputtedSealIdSealNotOutputDetailText;
    },
    inputtedSealId(state) {
        return state.inputtedSealId;
    },
    selectedMachine(state) {
        return state.selectedMachine;
    },
    inputtedMachineText(state) {
        return state.inputtedMachineText;
    },
    inputtedShop(state) {
        return state.inputtedShop;
    },
    inputtedDate(state) {
        return state.inputtedDate;
    },
    checkedImage(state) {
        return state.checkedImage;
    },
    selectedAccount(state) {
        return state.selectedAccount;
    },
    selectedPaymentKind(state) {
        return state.selectedPaymentKind;
    },
    selectedPaymentPrice(state) {
        return state.selectedPaymentPrice;
    },
    inputtedMail(state) {
        return state.inputtedMail;
    },
    checkedHowToRegister(state) {
        return state.checkedHowToRegister;
    },
    isValidatedMailList(state) {
        return state.isValidatedMailList;
    },
    isValidatedSealIdList(state) {
        return state.isValidatedSealIdList;
    },
    allState(state) {
        return state
    }
};

const actions = {
    updateInputtedText({ commit, state }, value) {
        commit('setInputtedText', value);
    },
    updateSelectedCarrier({ commit, state }, value) {
        commit('setSelectedCarrier', value);
    },
    updateSelectedSealId({ commit, state }, value) {
        commit('setSelectedSealId', value);
    },
    updateInputtedSealIdSealNotOutputDetailText({ commit, state }, value) {
        commit('setInputtedSealIdSealNotOutputDetailText', value);
    },
    updateInputtedSealId({ commit, state }, value) {
        commit('setInputtedSealId', value);
    },
    updateSelectedMachine({ commit, state }, value) {
        commit('setSelectedMachine', value);
    },
    updateInputtedMachineText({ commit, state }, value) {
        commit('setInputtedMachineText', value);
    },
    updateInputtedShop({ commit, state }, value) {
        commit('setInputtedShop', value);
    },
    updateInputtedDate({ commit, state }, value) {
        commit('setInputtedDate', value);
    },
    updateCheckedImage({ commit, state }, value) {
        commit('setCheckedImage', value);
    },
    updateSelectedAccount({ commit, state }, value) {
        commit('setSelectedAccount', value);
    },
    updateSelectedPaymentKind({ commit, state }, value) {
        commit('setSelectedPaymentKind', value);
    },
    updateSelectedPaymentPrice({ commit, state }, value) {
        commit('setSelectedPaymentPrice', value);
    },
    updateInputtedMail({ commit, state }, value) {
        commit('setInputtedMail', value);
    },
    updateCheckedHowToRegister({ commit, state }, value) {
        commit('setCheckedHowToRegister', value);
    },
    updateIsValidatedMailList({ commit, state }, value) {
        commit('setIsValidatedMailList', value);
    },
    updateIsValidatedSealIdList({ commit, state }, value) {
        commit('setIsValidatedSealIdList', value);
    },
    resetData({ commit, state }, value) {
        commit('clearData', value);
    },
};
const mutations = {
    setInputtedText(state, inputtedText) {
        state.inputtedText = inputtedText;
    },
    setSelectedCarrier(state, selectedCarrier) {
        state.selectedCarrier = selectedCarrier;
    },
    setSelectedSealId(state, selectedSealId) {
        state.selectedSealId = selectedSealId;
    },
    setInputtedSealIdSealNotOutputDetailText(state, inputtedSealIdSealNotOutputDetailText) {
        state.inputtedSealIdSealNotOutputDetailText = inputtedSealIdSealNotOutputDetailText;
    },
    setInputtedSealId(state, inputtedSealId) {
        state.inputtedSealId = inputtedSealId;
    },
    setSelectedMachine(state, selectedMachine) {
        state.selectedMachine = selectedMachine;
    },
    setInputtedMachineText(state, inputtedMachineText) {
        state.inputtedMachineText = inputtedMachineText;
    },
    setInputtedShop(state, inputtedShop) {
        state.inputtedShop = inputtedShop;
    },
    setInputtedDate(state, inputtedDate) {
        state.inputtedDate = inputtedDate;
    },
    setCheckedImage(state, checkedImage) {
        state.checkedImage = checkedImage;
    },
    setSelectedAccount(state, selectedAccount) {
        state.selectedAccount = selectedAccount;
    },
    setSelectedPaymentKind(state, selectedPaymentKind) {
        state.selectedPaymentKind = selectedPaymentKind;
    },
    setSelectedPaymentPrice(state, selectedPaymentPrice) {
        state.selectedPaymentPrice = selectedPaymentPrice;
    },
    setInputtedMail(state, inputtedMail) {
        state.inputtedMail = inputtedMail;
    },
    setCheckedHowToRegister(state, checkedHowToRegister) {
        state.checkedHowToRegister = checkedHowToRegister;
    },
    setIsValidatedMailList(state, isValidatedMailList) {
        state.isValidatedMailList = isValidatedMailList;
    },
    setIsValidatedSealIdList(state, isValidatedSealIdList) {
        state.isValidatedSealIdList = isValidatedSealIdList;
    },
    // stateを初期化する
    clearData(state) {
        Object.assign(state, getDefaultState())
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};