<template>
    <div class="l-wrap">
        <the-header :header-type="headerType" :unsaved-puri-count="unsavedPuriCount" :latest-image-id="latestImageId" :is-sticky="isHeaderSticky"/>
        <div class="l-content">
            <slot></slot>
        </div>
        <the-footer :footer-type="footerType"/>
    </div>
</template>

<script>
    import TheHeader from "../organisms/TheHeader.vue"
    import TheFooter from "../organisms/TheFooter.vue"

    export default {
        name: "BaseLayout",
        components: {
            TheFooter,
            TheHeader
        },
        props: {
            /**
             * Headerコンポーネント用
             * 見た目のタイプ
             */
            headerType: {
                type: Symbol,
                required: true
            },
            /**
             * Headerコンポーネント用
             * 未保存プリ数
             */
            unsavedPuriCount: {
                type: String,
                required: true
            },
            /**
             * Headerコンポーネント用
             * 最新プリID
             */
            latestImageId: {
                type: String,
                required: true
            },
            /**
             * Headerコンポーネント用
             * メニューボタンがスクロールについてくるようにするか
             */
            isHeaderSticky: {
                type: Boolean,
                required: false,
                default: true
            },
            /**
             * Footerコンポーネント用
             * 見た目のタイプ
             */
            footerType: {
                type: Symbol,
                required: true
            },
        },
    }
</script>
