import pictlinkApi from '../interceptor/AxiosInterceptor';
import * as urlUtil from '../../util/UrlUtil';

import UserProfileOutputData from "../output-data/UserProfileOutputData";
import UserProfileViewModel from "../../view-model/UserProfileViewModel";

/**
 * プロフィール取得APIを呼ぶ.
 * @param {AccountStatusViewModel} accountStatus
 *
 * @see https://github.com/furyu-cm1/voyager-sp/blob/epic/VOYAGER-9097_mypage/docs/account_api.md
 */
export function getUserProfile(accountStatus) {
    if(!accountStatus.membership.isGuest) {
        return pictlinkApi.get(urlUtil.apiEndpointUtil.userProfileApiUrl())
            .then(result =>
                new UserProfileOutputData(result)
            ).then(result => {
                return new UserProfileViewModel(result, accountStatus)
            })
    } else {
        //Guest(未ログイン)状態のときはnullを返す
        return null
    }
}
