/**
 * 最新プリ画のパスを組み立てる
 *
 * @param latestImageId
 * @returns {string}
 */
export function getLatestImageUrl(latestImageId) {
    return `/sp/2/mypage/image/${latestImageId}_thumb.jpg`
}

