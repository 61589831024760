/**
 * APIのレスポンスに含まれるプリのentity.
 */
export default class PuriOutputData {
    /**
     * @param data プリのentityを表すjsonフィールド群.
     */
    constructor(data) {
        this.imageId = data.imageId;           // 画像ID ※未保存の場合はnull
        this.sealId = data.sealId;             // シールID
        this.puriNumber = data.puriNumber;     // プリ番号
        this.registerdAt = data.registerdAt;   // シール保存日
        this.expiringAt = data.expiringAt;     // シールの保存期限 ※保存済みの場合はnull
        this.machineName = data.machineName;   // 撮影筐体名
        this.machineId = data.machineId;       // 筐体ID
        this.rawPuri = data.rawPuri;           // らくがきなし画像かどうか
        this.readable = data.readable;         // 画像の閲覧権限があるかど
    }
}
