import MobileDetect from 'mobile-detect'

/**
 * User-Agentから端末のプラットフォームを判定するクラス.
 */
export default class UserAgentUtil {
    constructor() {
        this.mobileDetect = new MobileDetect(window.navigator.userAgent);
    }
    /**
     * アプリかどうか
     * PICTLINK-169 "Pictlink-App"の対応バージョン以下の場合、
     * 使用するentrypointのvmからhiddenでtoolboxのbooleanを持ってきて判定を行う。
     * vmに記述：<div hidden id="isAppli">$clientTool.isAppli()</div>
     * 対応バージョン: [iOS]v7.15.1/[Android] v7.15.0
     * UA対応アプリが市場100%になればvmの記述は削除する。
     * * @return {boolean}
     */
    get isAppliAtVM() {
        // vmのtoolboxから取得したBoolean
        return document.getElementById('isAppli').textContent.toLowerCase() === "true";
    }
    get isAppliAtVue() {
        // mobileDetectで取得したBoolean
        return this.mobileDetect.match('Pictlink-App');
    }
    get isAppli() {
        return this.isAppliAtVM || this.isAppliAtVue
    }


    /**
     * iOS端末かどうか
     * @return {boolean}
     */
    get isIOS() {
        return this.mobileDetect.os() === 'iOS';
    }

    /**
     * Android端末かどうか
     * @return {boolean}
     */
    get isAndroid() {
        return this.mobileDetect.os() === 'AndroidOS';
    }

    /**
     * iOS9未満かどうか
     * @return {boolean}
     */
    get isLessThanIOS9() {
        return this.mobileDetect.version('iOS') < 9
    }

    /**
     * Android6未満かどうか
     * @return {boolean}
     */
    get isLessThanAndroid6() {
        return this.mobileDetect.version('Android') < 6
    }

    /**
     * 端末の種別を大文字で返す
     * @returns {string}
     */
    get osName() {
        return this.isIOS ? 'IOS' : this.isAndroid ? 'ANDROID' : 'OTHER';
    }
}

export const userAgentUtil = new UserAgentUtil();