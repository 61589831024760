/**
 *  アクセスユーザのメンバーシップを表すクラス.
 */
export default class Membership {
    /**
     * @param {String} status
     */
    constructor(status) {
        this.status = status;
    }

    /**
     * membershipがゲストかどうか
     */
    get isGuest() {
        return this.status === 'GUEST';
    }

    /**
     * membershipがミニかどうか
     */
    get isMini() {
        return this.status === 'MINI';
    }

    /**
     * membershipがスタンダードかどうか
     */
    get isStandard() {
        return this.status === 'STANDARD';
    }

    /**
     * membershipがプレミアムかどうか
     */
    get isPremium() {
        return this.status === 'PREMIUM';
    }

    /**
     * 課金コースに対応するランクを返す
     * ランクの数字が小さいほどヒエラルキーが高い
     */
    get billingRank() {
        return this.isPremium ? 1 : this.isStandard ? 2 : this.isMini ? 3 : 4
    }

    /**
     * 現在のmembershipから一段階課金した場合のmembership名を返す
     */
    get upgradeMembershipName() {
        return this.isPremium ? null : this.isStandard ? '有料会員プラス' : '有料会員'
    }

    /**
     * 現在のmembershipから一段階課金解除した場合のmembership名を返す
     */
    get downgradeMembershipName() {
        return this.isPremium ? '有料会員' : this.isStandard ? '無料会員' : null
    }
}