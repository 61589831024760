import PenaltyPuriGet from "./PenaltyPuriGetViewModel";
import PenaltySns from "./PenaltySnsViewModel";

export default class AccountDetailViewModel {
    constructor(data) {
        this.id = data.id;
        this.canAddon = data.canAddon;
        this.puriGetPenalty = new PenaltyPuriGet(data.purigetPenalty);
        this.snsPenalty = new PenaltySns(data.snsPenalty);
        this.isOfficial = data.official;
        this.mailAddress = data.mailAddress;
        this.registeredAt = data.registeredAt;
    }
}