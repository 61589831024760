import * as puriSealAction from '../../model/action/PuriSealAction'

const getDefaultState = () => {
    return {
        seals: null,
    }
};

export const state = getDefaultState();

const getters = {
    seals(state) {
        return state.seals
    }
}

const actions = {
    async getSeals({ commit }, sealId) {
        commit('setSeals', await puriSealAction.getPuriSeals(sealId));
    },
    // FormSealId.vueでのみ使用
    async getSealsV2({ commit }, sealId) {
        commit('setSeals', await puriSealAction.getPuriSealsV2(sealId));
    },
    resetData({ commit, state }, value) {
        commit('clearData', value);
    },
}

const mutations = {
    setSeals(state, seals) {
        state.seals = seals
    },
    // stateを初期化する
    clearData(state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}