import PrivacyPolicyStatusOutputData from "./PrivacyPolicyStatusOutputData";

/**
 * APIのレスポンスに含まれるプライバシーポリシーのEntity
 */
export default class PrivacyPolicyStatusesOutputData {
    constructor(data) {
        //サービスポリシーは全社共通のため'furyu'のkindで取得
        const servicePolicyStatus = data.find(p => p.kind === 'furyu')
        this.servicePolicy = servicePolicyStatus ? new PrivacyPolicyStatusOutputData(servicePolicyStatus) : null
        //データ送信特約はサービスごとにデータ送信さきが異なるため'pictlink'のkindで取得
        const specialContractStatus = data.find(p => p.kind === 'pictlink')
        this.specialContract = specialContractStatus ? new PrivacyPolicyStatusOutputData(specialContractStatus) : null
    }
}