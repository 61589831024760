<template>
    <div>
        <!-- このコンポーネントでGAイベント送信 -->
        <div v-if="link" data-jest="active-btn" @click="ga4ClickEvent">
            <div v-if="isRouterLink">
                <!--
                    router-link toで遷移するとタイミングの問題でGAイベントが正しく送信されないため
                    ga4ClickEventの中でrouter.pushで遷移
                    TODO: router-link toで遷移している箇所はユニテ要検討
                -->
                <div :class="[this.priorityClassName, this.multipleClassName]" v-html="text" :data-jest="`btn-${priority.description}`"/>
            </div>
            <a
                v-else
               :href="this.link"
               :target="this.isExternalLink? '_blank': '_self'"
               :class="[this.priorityClassName, this.multipleClassName]"
               v-html="text"
               :data-jest="`btn-${priority.description}`"
            />
        </div>
        <!-- 親コンポーネントでGAイベント要設定 -->
        <div v-else>
            <!-- FIXME: divタグにする（GA4リリース後）-->
            <a tabindex="-1" :data-jest="`btn-${priority.description}`">
                <div :class="[this.priorityClassName, this.multipleClassName]" v-html="text"/>
            </a>
        </div>
    </div>
</template>

<script>
    import { cssPriority } from '../../view-model/enum/CSSPriority'
    import {getClickAllEvent} from "../../util/Ga4EventUtil.js";

    export default {
        name: "Btn",
        props: {
            /**
             * 遷移先URL
             */
            link: {
                type: String,
                required: false
            },
            /**
             * テキスト
             */
            text: {
                type: String,
                required: false
            },
            /**
             * 内包テキストが複数行かどうか
             */
            isMultiple: {
                type: Boolean,
                required: false,
                default: false
            },
            /**
             * ボタンのページ内優先度
             */
            priority: {
                type: Symbol,
                required: false,
                default: cssPriority.DEFAULT
            },
            /**
             * vue-routerでの遷移かどうか
             */
            isRouterLink: {
                type: Boolean,
                required: false,
                default: false
            },
            /**
             * 別タブでの遷移かどうか.
             * isRouterLinkがtrueの場合は動作しません
             */
            isExternalLink: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        methods: {
            ga4ClickEvent() {
              /**
               * linkが渡されてこない場合はこのコンポーネントでのclick_allイベント送信はしないようにしています。
               * それぞれの親コンポーネントでgetClickAllEventを適用するようにしてください。
               */
                if(this.priority !== cssPriority.DISABLED) {
                  if(this.link) {
                    if (this.isRouterLink) {
                      getClickAllEvent(location.pathname + '#' + this.link, this.text)
                      this.$router.push(this.link)
                    } else {
                      getClickAllEvent(this.link, this.text)
                    }
                  }
                }
            }
        },
        computed: {
            priorityClassName() {
                switch (this.priority) {
                    case cssPriority.PRIMARY:
                        return 'm-btn-primary';
                    case cssPriority.SECONDARY:
                        return 'm-btn-secondary';
                    case cssPriority.DISABLED:
                        return 'm-btn-disabled';
                    default:
                        return 'm-btn-default';
                }
            },
            multipleClassName() {
                if(this.isMultiple) {
                    switch (this.priority) {
                        case cssPriority.PRIMARY:
                            return 'm-btn-primary_multiple';
                        case cssPriority.SECONDARY:
                            return 'm-btn-secondary_multiple';
                        case cssPriority.DISABLED:
                            return 'm-btn-disabled_multiple';
                        default:
                            return 'm-btn-default_multiple';
                    }
                }
                return '';
            }
        }
    }
</script>
