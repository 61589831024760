<template>
    <div>
        <div class="l-element">
            <p class="c-privacy-policy-agreement-contents-title">サービスプライバシーポリシー</p>
            <scroll-box box-height="106">
                <div class="c-privacy-policy-agreement-contents-iframe">
                    <iframe :src="servicePolicyUrl" class="c-privacy-policy-agreement-contents-iframe-item" data-jest="service-policy-area"/>
                </div>
            </scroll-box>
        </div>
        <div class="l-element">
            <p class="c-privacy-policy-agreement-contents-title">データ送信特約</p>
            <scroll-box box-height="106" class="is-mgb8">
                <div class="c-privacy-policy-agreement-contents-iframe">
                    <iframe :src="specialContractUrl" class="c-privacy-policy-agreement-contents-iframe-item" data-jest="special-contract-area"/>
                </div>
            </scroll-box>
            <p class="c-privacy-policy-agreement-contents-text">※退会時の際にもプライバシーポリシーの同意が必要です。</p>
        </div>
    </div>
</template>

<script>
import ScrollBox from "../atoms/ScrollBox.vue";
export default {
    name: "PrivacyPolicyAgreementContents",
    components: { ScrollBox },
    props: {
        /**
         * サービスプライバシーポリシーに表示するURL
         */
        servicePolicyUrl: {
            type: String,
            required: true
        },
        /**
         * 特約に表示するURL
         */
        specialContractUrl: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
// ベースとなるデバイス幅
$grid-device-width: 375px;
// 基本の余白
$grid-base: 4.27vw; // 375px幅に対して16px
$grid-consistent: $grid-base * 0.5;// 375px幅に対して8px

.c-privacy-policy-agreement-contents {
    &-iframe {
        width: 100%;
        /**
         * 以下、一部iosでiframeのコンテンツがスクロールできないため
         * iframeタグの親で対策用のスタイルを記述
         */
        -webkit-overflow-scrolling: touch;
        overflow: auto;
        height: 106px;
        &-item {
            width: 100%;
        }
    }
    &-title,
    &-text {
        font-size: 1.2rem;
        margin-bottom: $grid-consistent;
        line-height: 1.83;
    }
    &-text {
        line-height: 1.75;
    }
}
</style>