<template>
    <div class="c-error-reload-contents">
        <heading-appeal-primary text="読み込みに失敗しました" />
        <div @click="onReload" class="is-text-center" data-jest="reload-button">
            <img src="/img/v2/reload_button.svg" alt="リロード" class="c-error-reload-contents-button" @load="emit('load', 'done')"/>
        </div>
        <heading-appeal-primary text="タップして再読み込み" />
    </div>
</template>

<script>
import HeadingAppealPrimary from "../atoms/HeadingAppealPrimary.vue";
import {getGa4EventLabel} from "../../util/Ga4EventUtil";
export default {
    name: "ErrorReadContents",
    components: {HeadingAppealPrimary},
    props: {
        gaEventLabel: {
            type: String,
            required: true
        }
    },
    emits: ['load'],
    methods: {
        onReload() {
            getGa4EventLabel('TAP_RELOAD', this.gaEventLabel)
            location.reload()
        },
        emit(emitName, value) {
            this.$emit(emitName, value);
        },
    }
}
</script>

<style lang="scss" scoped>
.c-error-reload-contents {
    &-button {
        width: 78px;
        margin: 25px 0;
    }
    :deep(.m-heading-primary) {
        font-size: 1.4rem;
    }
}
</style>
