import * as urlUtil from '../../util/UrlUtil'
import pictlinkApiV2 from '../interceptor/AxiosInterceptorV2';
import NewsViewModel from "../../view-model/NewsViewModel";
import NewsOutputData from "../output-data/NewsOutputData";

/**
 * 最新のユーザへのお知らせを全件取得する
 * @param {String} categoryName お知らせカテゴリ名
 */
export function fetchNewsList(categoryName) {
    return pictlinkApiV2.get(urlUtil.apiEndpointUtil.newsInfoApiUrl(categoryName))
        .then(result => {
            const list = result.entry
            if(list.length > 0) {
                return list.map(p => new NewsOutputData(p))
            } else {
                return null
            }
        })
        .then(result => {
            return result.map(p => new NewsViewModel(p))
        } )
        .catch(error => {
            console.log(error)
            return null
        });
}
