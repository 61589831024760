<template>
    <div>
        <!--フッターパターン/all-->
        <div v-if="isAll" class="footer-wrap all">
            <nav class="support_menu">
                <ul>
                    <li><link-text :link="topInformationUrl" text="サイトTOP" data-jest="top-link"/></li>
                    <li><link-text :link="termsOfServiceUrl" text="利用規約" data-jest="terms-link"/></li>
                    <li><link-text :link="helpTopUrl" text="ヘルプ" data-jest="help-link"/></li>
                    <li><link-text :link="inquiryUrl" text="お問い合わせ" data-jest="inquiry-link"/></li>
                    <!--プラス会員向け-->
                    <li v-if="membership.isPremium">
                        <link-text v-if="isDocomoPayment" :link="paymentCancelUrl" text="マイメニュー解約" data-jest="for-premium-docomo-cancel-link"/>
                        <link-text v-else :link="paymentCancelUrl" text="有料会員解約" data-jest="for-premium-cancel-link"/>
                    </li>
                    <!--スタンダード会員向け-->
                    <li v-if="membership.isStandard">
                        <link-text :link="paymentAddOnUrl" text="プラス会員登録" data-jest="for-standard-payment-link"/>
                    </li>
                    <li v-if="membership.isStandard">
                        <link-text v-if="isDocomoPayment" :link="paymentCancelUrl" text="マイメニュー解約" data-jest="for-standard-docomo-cancel-link"/>
                        <link-text v-else :link="paymentCancelUrl" text="有料会員解約" data-jest="for-standard-cancel-link"/>
                    </li>
                    <!--無料会員向け-->
                    <li v-if="membership.isMini">
                        <link-text :link="paymentBaseUrl" text="有料会員登録" data-jest="for-mini-payment-link"/>
                    </li>
                    <!--公認アカウント以外向け-->
                    <li v-if="membership.isMini && !isOfficial">
                        <link-text link="/sp/account/leave" text="無料会員削除" data-jest="for-mini-cancel-link"/>
                    </li>
                    <!--非会員orログアウトしてる人向け-->
                    <li v-if="membership.isGuest">
                        <link-text :link="directPaymentUrl" text="有料会員登録" data-jest="for-guest-payment-link"/>
                    </li>
                    <li><link-text :link="privacyUrl" text="個人情報保護方針" data-jest="privacy-link"/></li>
                    <li><link-text :link="tokuteiUrl" text="特定商取引に基づく表示" data-jest="tokutei-link"/></li>
                    <li><link-text :link="privacyListUrl" text="利用者情報の外部送信" data-jest="privacyListUrl" newWindow="true"></link-text></li>
                    <li><link-text link="/sp/common/information/operatingPolicy" text="健全化に資する運用方針" data-jest="operating-policy-link"/></li>
                    <li><link-text :link="privacyPolicyUrl" text="プライバシーポリシー" data-jest="privacy-policy-link"/></li>
                    <!--未ログイン-->
                    <li v-if="membership.isGuest"><link-text :link="loginUrl" text="ログイン" data-jest="login-link"/></li>
                    <!--会員向け-->
                    <li v-else><link-text link="/sp/setting/setting" text="登録変更・ログアウト" data-jest="logout-link"/></li>
                </ul>
            </nav>
        </div>
        <!--フッターパターン/primary-->
        <div v-else-if="isPrimary" class="footer-wrap primary">
            <nav class="support_menu">
                <ul>
                    <li><link-text :link="topInformationUrl" text="サイトTOP" data-jest="top-link"/></li>
                    <li><link-text :link="helpTopUrl" text="ヘルプ" data-jest="help-link"/></li>
                    <!--スタンダード会員向け-->
                    <li v-if="membership.isStandard"><link-text :link="paymentBaseUrl" text="プラス会員登録" data-jest="for-standard-link"/></li>
                    <!--無料会員向け-->
                    <li v-else-if="membership.isMini"><link-text :link="paymentBaseUrl" text="有料会員登録" data-jest="for-mini-link"/></li>
                    <!--非会員or未ログイン向け-->
                    <li v-else-if="membership.isGuest"><link-text :link="directPaymentUrl" text="有料会員登録" data-jest="for-guest-link"/></li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    import * as urlUtil from "../../util/UrlUtil";
    import LinkText from "../atoms/LinkText.vue";
    import Membership from "../../view-model/MembershipViewModel";
    import {footerType} from "../../view-model/enum/FooterType";

    export default {
        name: "FooterMenu",
        components: {LinkText},
        data() {
            return {
                helpTopUrl: urlUtil.helpUrlUtil.helpTopUrl("pictlink_web"),
                paymentBaseUrl: urlUtil.servletUrlUtil.paymentBaseUrl(),
                directPaymentUrl: urlUtil.servletUrlUtil.directPaymentUrl(),
                inquiryUrl: urlUtil.servletUrlUtil.inquiryUrl(),
                paymentAddOnUrl: urlUtil.servletUrlUtil.paymentAddOnUrl(),
                loginUrl: urlUtil.servletUrlUtil.loginUrl(),
                termsOfServiceUrl: urlUtil.policyUrlUtil.termsOfServiceUrl(),
                privacyUrl: urlUtil.policyUrlUtil.privacyUrl(),
                tokuteiUrl: urlUtil.policyUrlUtil.tokuteiUrl(),
                privacyPolicyUrl: urlUtil.policyUrlUtil.privacyPolicyUrl(),
                topInformationUrl: '/sp/top/information',
                paymentCancelUrl: urlUtil.servletUrlUtil.paymentCancelUrl(),
                privacyListUrl: 'https://www.furyu.jp/privacy_list.html'
            }
        },
        props: {
            membership: {
                type: Membership,
                required: true
            },
            footerType: {
                type: Symbol,
                required: true
            },
            /**
             * vm経由でtoolboxから情報を取得している
             * 相応のAPIができればこちらのpropsは不要
             */
            isDocomoPayment: {
                type: Boolean,
                required: false
            },
            isOfficial: {
                type: Boolean,
                required: false
            }
        },
        computed: {
            isAll() {
                return this.footerType === footerType.ALL
            },
            isPrimary() {
                return this.footerType === footerType.PRIMARY
            }
        },
    }
</script>
