
const state = {
    familyName: "",
    givenName: "",
    birthYear: "",
    birthMonth: "",
    birthDay: "",
    mailAddress: "",
    fileCertificateKind: null,
    fileCertificateInsurance: null,
    fileCertificateSchoolIssuedFront: null,
    fileCertificateSchoolIssuedBack: null,
}

const getters = {
    familyName(state) {
        return state.familyName
    },
    givenName(state) {
        return state.givenName
    },
    birthYear(state) {
        return state.birthYear
    },
    birthMonth(state) {
        return state.birthMonth
    },
    birthDay(state) {
        return state.birthDay
    },
    mailAddress(state) {
        return state.mailAddress
    },
    fileCertificateKind(state) {
        return state.fileCertificateKind
    },
    fileCertificateInsurance(state) {
        return state.fileCertificateInsurance
    },
    fileCertificateSchoolIssuedFront(state) {
        return state.fileCertificateSchoolIssuedFront
    },
    fileCertificateSchoolIssuedBack(state) {
        return state.fileCertificateSchoolIssuedBack
    },
}

const actions = {
    updateFamilyName({ commit, state }, value) {
        commit('setFamilyName', value);
    },
    updateGivenName({ commit, state }, value) {
        commit('setGivenName', value);
    },
    updateBirthYear({ commit, state }, value) {
        commit('setBirthYear', value);
    },
    updateBirthMonth({ commit, state }, value) {
        commit('setBirthMonth', value);
    },
    updateBirthDay({ commit, state }, value) {
        commit('setBirthDay', value);
    },
    updateMailAddress({ commit, state }, value) {
        commit('setMailAddress', value);
    },
    updateFileCertificateKind({ commit, state }, value) {
        commit('setFileCertificateKind', value);
    },
    updateFileCertificateInsurance({ commit, state }, value) {
        commit('setFileCertificateInsurance', value);
    },
    updateFileCertificateSchoolIssuedFront({ commit, state }, value) {
        commit('setFileCertificateSchoolIssuedFront', value)
    },
    updateFileCertificateSchoolIssuedBack({ commit, state }, value) {
        commit('setFileCertificateSchoolIssuedBack', value)
    },
}

const mutations = {
    setFamilyName(state, familyName) {
        state.familyName = familyName;
    },
    setGivenName(state, givenName) {
        state.givenName = givenName;
    },
    setBirthYear(state, birthYear) {
        state.birthYear = birthYear;
    },
    setBirthMonth(state, birthMonth) {
        state.birthMonth = birthMonth;
    },
    setBirthDay(state, birthDay) {
        state.birthDay = birthDay;
    },
    setMailAddress(state, mailAddress) {
        state.mailAddress = mailAddress;
    },
    setFileCertificateKind(state, fileCertificateKind) {
        state.fileCertificateKind = fileCertificateKind;
    },
    setFileCertificateInsurance(state, fileCertificateInsurance) {
        state.fileCertificateInsurance = fileCertificateInsurance;
    },
    setFileCertificateSchoolIssuedFront(state, fileCertificateSchoolIssuedFront) {
        state.fileCertificateSchoolIssuedFront = fileCertificateSchoolIssuedFront;
    },
    setFileCertificateSchoolIssuedBack(state, fileCertificateSchoolIssuedBack) {
        state.fileCertificateSchoolIssuedBack = fileCertificateSchoolIssuedBack
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
