import { createStore } from 'vuex'
import AccountStoreV2 from './AccountStoreV2'
import MyPageNavigationStore from './MyPageNavigationStore'
import InquiryStore from './InquiryStore'
import FuryuBannerStore from './FuryuBannerStore'
import PuriSealsStore from './PuriSealsStore'
import JhsFreeStore from './JhsFreeStore'
import JhsFreeEntryStore from './JhsFreeEntryStore'
import PuriGetCompleteStore from './PuriGetCompleteStore'
import NewsStore from "./NewsStore";
import PictlinkAdStore from "./PictlinkAdStore";

const modules = {
    modules: {
        AccountStoreV2,
        MyPageNavigationStore,
        InquiryStore,
        FuryuBannerStore,
        PuriSealsStore,
        JhsFreeStore,
        JhsFreeEntryStore,
        PuriGetCompleteStore,
        NewsStore,
        PictlinkAdStore
    }
};
const Store = createStore(modules);

export { Store, modules }
