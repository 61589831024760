
export default class NewsOutputData {
    constructor(data) {
        this.id = data.id;
        this.newsCategoryName = data.newsCategoryName;
        this.createdAt = data.createdAt;
        this.title = data.title;
        this.body = data.body;
        this.link = new LinkOutputData(data.link);
        this.priorityLevel = data.priorityLevel;
    }
}

class LinkOutputData {
    constructor(data) {
        this.label = data.label;
        this.url = data.url;
    }
}