const state = {
    machineIdentifier: ""
};

const getters = {
    machineIdentifier: (state) => state.machineIdentifier
};

const mutations = {
    setMachineIdentifier (state, payload) {
        state.machineIdentifier = payload
    }
};

const actions = {
    updateMachineIdentifier ({ commit }, payload) {
        commit('setMachineIdentifier', payload.identifier)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
