/**
 * AccountStatusViewModel以外のViewModelからTimeTripを参照するための拡張クラス
 *
 * 利用例)
 * HogeViewModelでTimeTripの時刻を知りたい場合
 *
 * export default class HogeViewModel extends TimeTripViewModel {
 *  constructor(data, _) {
 *     super(data, _);
 *     ........
 *
 *     console.log(this.timeTripDate)
 *  }
 *
 *  ※HogeViewModelのnew時の第二引数にAccountStatusを渡すこと
 *
 */
export default class TimeTripViewModel {
    constructor(_, accountStatus) {
        this.timeTripDate = accountStatus.timeTripDate
    }
}
