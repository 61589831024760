/**
 * 指定されたシールIDに紐づくプリを取得するAPI(V2)のレスポンスに含まれる筐体情報のentity.
 */
export default class PuriSealsMachineViewModel {
    /**
     * @param data プリの筐体情報のentityを表すjsonフィールド群.
     */
    constructor(data) {
        this.id = data.id;                  // 筐体ID
        this.name = data.name;              // 筐体名
        this.seriesId = data.seriesId;      // 筐体シリーズID
        this.version = data.version;        // 筐体version
    }
}
