import PuriViewModel from "./PuriViewModel";
import * as dateUtil from "../util/DateUtil";
import {calcDayBefore} from "../util/DateUtil";

/**
 * 未保存プリの状況を表現するview model.
 */
export default class UnsavedPuriViewModel {
    /**
     * @param data PuriListWithPaginationOutputData nullを取りうる
     */
    constructor(data) {
        this.hasUnsavedPuri = data ? data.totalCount > 0 : false;
        this.totalCount = data ? data.totalCount : 0;
        this.items = data ? data.items.map(i => new PuriViewModel(i)) : null;
    }

    // あとN日で消えちゃうよ、の表現のために最短のN日を計算する
    get expiringDateCount() {
        return this.items && this.items.length > 0 ? this.items.map(i => i.expiringDateCount).reduce((a, b) => a < b ? a : b) : null;
    }

    // プリゲット表示統一の為に1日前の日付を返す
    get puriGetExpiredAt() {
        if(!this.items || this.items.length <= 0) return null;
        return dateUtil.calcDayBefore(
            this.items.map(i => i.expiringAt)
                .reduce((a, b) => a < b ? a : b)
        );
    }
}
