<template>
    <div class="furyu_puri_official_sns">
        <a class="icon_furyu_puri_official_twitter" :href="link" target="_blank" data-jest="official-twitter-link" @click="ga4ClickEvent">{{text}}</a>
    </div>
</template>

<script>
import {getClickAllEvent} from "../../util/Ga4EventUtil";

export default {
    name: "FooterSns",
    data() {
        return {
            link: 'https://twitter.com/FURYU_Puri',
            text: 'フリューのプリ公式 X'
        }
    },
    methods: {
        ga4ClickEvent() {
            getClickAllEvent(this.link, this.text)
        }
    }
}
</script>