import * as accountAction from '../../model/action/AccountActionV2'
import * as unsavedPuriAction from '../../model/action/UnsavedPuriAction'
import * as userProfileAction from '../../model/action/UserProfileAction'
import * as paymentAction from '../../model/action/PaymentAction'
import * as paymentUnsubscribableAction from "../action/PaymentUnsubscribableAction";
import * as AccountActionV2 from "../action/AccountActionV2";

/**
 * ログインユーザの情報をすべからく管理するStore
 */
const state = {
    accountStatus: null,
    userProfile: null,
    unsavedPuri: null,
    payment: null,
    paymentTrial: null,
    accessClient: null,
    isAppli: false,
    paymentUnsubscribable: null,
};

const getters = {
    accountStatus(state) {
        return state.accountStatus
    },
    userProfile(state) {
        return state.userProfile
    },
    unsavedPuri(state) {
        return state.unsavedPuri
    },
    payment(state) {
        return state.payment
    },
    paymentTrial(state) {
        return state.paymentTrial
    },
    accessClient(state) {
        return state.accessClient
    },
    isAppli(state) {
        return state.isAppli
    },
    paymentUnsubscribable(state) {
        return state.paymentUnsubscribable
    }
};

const actions = {
    /**
     * アカウントのシステム情報を取得して永続化する
     */
    async getAccountStatus ({ commit }) {
        commit('setAccountStatus', await accountAction.getAccountStatus())
    },

    /**
     * アカウントの課金情報を取得して永続化する
     */
    async getPayment ({ commit }) {
        commit('setPayment', await paymentAction.getPayment())
    },

    /**
     * お試し登録の状況を取得して永続化する
     */
    async getPaymentTrial ({ commit }) {
        commit('setPaymentTrial', await paymentAction.getPaymentTrial())
    },

    /**
     * 未保存プリの状況を取得して永続化する
     */
    async getUnsavedPuri ({ dispatch, commit }) {
        commit('setUnsavedPuri', await unsavedPuriAction.getUnsavedPuri(state.accountStatus.membership));
    },

    /**
     * サイトトップ表示に必要な情報を取得して永続化する
     */
    async getStatusForTop ({ dispatch, commit }) {
        await dispatch('getAccountStatus'); // getAccountStatusが完了するのを待機する
        // アカウントの所持未保存プリ状況を取得して永続化する
        commit('setUnsavedPuri', await unsavedPuriAction.getUnsavedPuri(state.accountStatus.membership));
        // アカウントのプロフィールを取得して永続化する
        commit('setUserProfile', await userProfileAction.getUserProfile(state.accountStatus))
    },

    /**
     * マイページ表示に必要な情報を取得して永続化する
     * vue-router初期化時にaccountStatusが必ずセットされるのでここで再dispatchしない
     * @see myPage.js
     */
    async getStatusForMyPage ({ dispatch, commit}) {
        //アカウントの所持未保存プリ状況を取得して永続化する
        commit('setUnsavedPuri', await unsavedPuriAction.getUnsavedPuri(state.accountStatus.membership));
        // アカウントのプロフィールを取得して永続化する
        commit('setUserProfile', await userProfileAction.getUserProfile(state.accountStatus))
    },

    async getUserProfile ({ dispatch, commit }) {
        await dispatch('getAccountStatus'); // getAccountStatusが完了するのを待機する
        // アカウントのプロフィールを取得して永続化する
        commit('setUserProfile', await userProfileAction.getUserProfile(state.accountStatus))
    },

    /**
     * ユーザの端末ネットワーク状況を取得して永続化する.
     * 寿命の長いvueインスタンスで呼び出す場合は更新に注意すること.
     */
    async getAccessClient ({ commit }) {
        commit('setAccessClient', await accountAction.getAccessClient())
    },

    /**
     * 指定されたBoolean値をisAppliにセットする
     * @Deprecated clientのUA利用は仕様として存在しないため、API仕様にあるものを使いたい。今後はsetupIsAppliを使用すること
     * API仕様: https://furyu.atlassian.net/wiki/spaces/VOYAGER/pages/39915127
     */
    getIsAppli({ commit, state }, value) {
        commit('setIsAppli', value);
    },

    /**
     * アプリからのアクセスかどうかをisAppliにセットする
     * /sp/2/user/access から取得した値をセットする
     */
    async setupIsAppli({ commit }) {
        const value = await AccountActionV2.getAccessClient()
        .then(result => {
            return result.client === 'APPLI'
        });
        commit('setIsAppli', value);
    },

    async getUnsubscribable ({ dispatch, commit }) {
        await dispatch('getAccountStatus'); // getAccountStatusが完了するのを待機する
        commit('setPaymentUnsubscribable', await paymentUnsubscribableAction.getPaymentUnsubscribable(state.accountStatus))
    },
};

const mutations = {
    setAccountStatus (state, accountStatus) {
        state.accountStatus = accountStatus
    },
    setUserProfile (state, userProfile) {
        state.userProfile = userProfile
    },
    setUnsavedPuri (state, unsavedPuri) {
        state.unsavedPuri = unsavedPuri
    },
    setPayment (state, payment) {
        state.payment = payment
    },
    setPaymentTrial (state, paymentTrial) {
        state.paymentTrial = paymentTrial
    },
    setAccessClient (state, accessClient) {
        state.accessClient = accessClient
    },
    setIsAppli (state, isAppli) {
        state.isAppli = isAppli
    },
    setPaymentUnsubscribable(state, paymentUnsubscribable) {
        state.paymentUnsubscribable = paymentUnsubscribable
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
