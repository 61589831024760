import * as urlUtil from '../../util/UrlUtil'
import authorizationApi from '../interceptor/AuthorizationAxiosInterceptor';
import PictlinkAdOutputData from "../output-data/PictlinkAdOutputData";
import PictlinkAdViewModel from "../../view-model/PictlinkAdViewModel";
import * as S3RequestUtil from "../../util/S3RequestUtil";

/**
 * ログインしているアカウントに応じた広告を全件取得する
 * @param {String} spaceId 広告枠の識別子
 */
export function fetchPictlinkAdList(spaceId) {
    return authorizationApi.get(urlUtil.apiEndpointUtil.getPictlinkAdApiUrl(spaceId))
        .then(result => {
            return result.data.map(p => new PictlinkAdOutputData(p))
        })
        .then(result => {
            return result.map(p => new PictlinkAdViewModel(p))
        })
        .catch(error => {
            throw error
        });
}

/**
 * アクセスユーザに対する広告への反応を送信する
 * @param {String} advertisementId 広告取得APIで取得した広告を一意に識別するID
 * @param {String} conversionId 広告取得APIで取得したリクエスト毎に割り振られるID
 */
export function reactPictlinkAd(advertisementId, conversionId) {
    const apiPath = urlUtil.apiEndpointUtil.reactPictlinkAdApiUrl(advertisementId)
    const requestBody = { conversionId: conversionId };

    return authorizationApi.post(apiPath, requestBody)
        .then(result => {
            return result
        })
        .catch(error => {
            throw error
        });
}

/**
 * 指定した場所のspaceIdを取得する
 */
export function getPictlinkAdSpaceId(spaceKind) {
    return S3RequestUtil.getDataSource(urlUtil.s3JsonUrlUtil.S3JsonUrl("PictlinkAdSpaceId"))
        .then(result => {
            return result[spaceKind]
        })
}