<template>
    <base-layout
        :header-type="headerType.LOGOONLYDISABLED"
        :footer-type="footerType.COPYRIGHTONLY"
        latest-image-id="dummy"
        unsaved-puri-count="0"
    >
        <div v-if="privacyPolicyStatuses && privacyPolicyStatuses.servicePolicy && privacyPolicyStatuses.specialContract"
             id="TEST-PRIVACY-POLICY-AGREEMENT">
            <heading-section title="プライバシーポリシーの同意"/>
            <div class="l-element">
                <heading-appeal-secondary :is-primary="true">
                    以下２箇所のプライバシーポリシーに<br>同意してください
                </heading-appeal-secondary>
            </div>
            <privacy-policy-agreement-contents :service-policy-url="privacyPolicyStatuses.servicePolicy.url"
                                               :special-contract-url="privacyPolicyStatuses.specialContract.url"
                                               class="is-mgb4"/>
            <div v-if="isSendError" class="l-element is-full-height">
                <p class="m-text-comments is-caution" data-jest="error-text">
                    通信に失敗しました。もう一度「同意する」ボタンを押してください。
                </p>
            </div>
            <div class="l-element is-full-height">
                <btn :priority="agreeButtonPriority" @click="setAgree" text="同意する"
                     id="TEST-PRIVACY-POLICY-AGREEMENT-BTN" data-jest="agreement-btn"/>
            </div>
        </div>
        <loading-heart v-else-if="isLoading"/>
        <error-reload v-else ga-event-label="PRIVACY_POLICY_AGREEMENT_ERROR" data-jest="error-reload"/>
    </base-layout>
</template>

<script>
import BaseLayout from "../templates/BaseLayout.vue";
import Btn from "../atoms/Btn.vue";
import {cssPriority} from "../../view-model/enum/CSSPriority";
import HeadingSection from "../atoms/HeadingSection.vue";
import * as urlUtil from "../../util/UrlUtil"
import PrivacyPolicyAgreementContents from "../organisms/PrivacyPolicyAgreementContents.vue";
import LoadingHeart from '../atoms/LoadingHeart.vue'
import HeadingAppealSecondary from "../atoms/HeadingAppealSecondary.vue";
import {getGa4EventLabel} from "../../util/Ga4EventUtil";
import * as privacyPolicyAgreementAction from "../../model/action/PrivacyPolicyAgreementAction.js";
import ErrorReload from "../organisms/ErrorReload.vue";
import {headerType} from "../../view-model/enum/HeaderType";
import {footerType} from "../../view-model/enum/FooterType";

export default {
    name: "AgreementPrivacyPolicy",
    components: {
        BaseLayout,
        HeadingAppealSecondary,
        PrivacyPolicyAgreementContents,
        HeadingSection,
        Btn,
        LoadingHeart,
        ErrorReload,
    },
    data() {
        return {
            cssPriority: cssPriority,
            isExecutionApi: false,
            isSendError: false,
            isLoading: true,
            privacyPolicyStatuses: null,
            afterAgreementUrl: new URLSearchParams(window.location.search).get("afterAgreement"),
            headerType: headerType,
            footerType: footerType
        }
    },
    computed: {
        /**
         * ボタンの活性/非活性切り替え
         */
        agreeButtonPriority() {
            if(this.isExecutionApi) {
                // API実行中は2重POST防止のためボタンを非活性にする
                return cssPriority.DISABLED
            } else {
                return cssPriority.PRIMARY
            }
        },
    },
    mounted() {
        this.getPolicyUrl()
    },
    methods: {
        /**
         * ボタンTAPでPOST(/sp/2/users/self/privacy_policy_statuses)
         * ログイン状態の場合、DBに同意情報を登録する
         * ログイン状態にかかわらずCookieに同意フラグを持たせる
         * afterAgreementパラメータのurlに遷移する
         */
        setAgree() {
            this.isExecutionApi = true;
            privacyPolicyAgreementAction.setPrivacyPolicyAgreement(this.privacyPolicyStatuses)
                .then(result => {
                    this.isSendError = false;
                    getGa4EventLabel('FORM_BUTTON', 'PRIVACY_POLICY_AGREEMENT')
                    if (this.afterAgreementUrl) {
                        this.pageTransition(this.afterAgreementUrl)
                    } else {
                        this.pageTransition(urlUtil.servletUrlUtil.topUrl())
                    }
                })
                .catch(error => {
                    getGa4EventLabel('FORM_BUTTON', 'PRIVACY_POLICY_AGREEMENT_ERROR')
                    this.isSendError = true;
                    this.isExecutionApi = false;
                });
        },
        getPolicyUrl() {
            privacyPolicyAgreementAction.getPrivacyPolicyStatuses()
                .then(result => {
                    this.privacyPolicyStatuses = result
                    this.isLoading = false
                })
                .catch(error => {
                    this.isLoading = false
                });
        },
        pageTransition(nextUrl) {
            location.href = nextUrl
        },
    }
}
</script>

<style scoped lang="scss">
    :deep(.m-heading-section) {
        margin-bottom: 0;
    }
    :deep(.m-heading-secondary) {
        font-weight: bold;
    }
</style>
