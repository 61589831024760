import axios from 'axios'
import Cookies from 'js-cookie';

const pictlinkSessionId = Cookies.get('_pictlink_session')

const authorizationApi = axios.create({
    headers: {
        Authorization: `Bearer ${pictlinkSessionId}`
    },
});

/**
 * 基本通信仕様に則ったAPIのエラーハンドリングの共通処理
 */
authorizationApi.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    //全API共通のエラー処理はここに実装
    throw error
});

export default authorizationApi