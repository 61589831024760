/**
 * ヘッダータイプを表すENUM
 */
const headerType = Object.freeze({
    ALL: Symbol('all'),
    FREEREGISTRATIONBTN: Symbol('freeRegistrationBtn'),
    LOGOONLY: Symbol('logoOnly'),
    LOGOONLYDISABLED: Symbol('logoOnlyDisabled')
});

export { headerType };