<template>
    <div class="m-img-loading-heart" id="js-loading-heart"></div>
</template>

<script>
    import * as documentElementSizeUtil from "../../util/documentElementSizeUtil.js";

    export default {
        name: "LoadingHeart",
        props: {
            /**
             * ローディング画面の高さ.
             * nullの場合は全画面サイズ（ヘッダー・フッターの高さを除く）になる
             */
            height: {
                type: Number,
                default: null,
                required: false
            }
        },
        methods: {
            setHeightFullSize() {
                let loadingDiv = document.getElementById('js-loading-heart');
                loadingDiv.style.margin = (documentElementSizeUtil.getMainContentClientHeight()-32)/2 + "px auto";
            },
            setHeight(height) {
                let loadingDiv = document.getElementById('js-loading-heart');
                loadingDiv.style.margin = height + "px auto";
            },
        },
        mounted() {
            if(this.height){
                this.setHeight(this.height);
            } else {
                this.setHeightFullSize()
            }
        },
    }
</script>