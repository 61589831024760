<template>
    <div class="header-btn" data-jest="header-btn" @click="ga4ClickEvent">
        <!--非ログイン時-->
        <div v-if="membership.isGuest">
            <a class="header-btn-link login" data-jest="guest-link" :href="btnUrl">
                <span>{{ btnText }}</span>
            </a>
        </div>
        <!--/非ログイン時-->
        <!--ログイン時-->
        <div v-else>
            <a class="header-btn-link mypage" data-jest="member-link" :href="btnUrl">
                <span>{{ btnText }}</span>
                <!--アイコン-->
                <img v-if="latestImageId === ''" src="/img/icon/icon_mypage.png" :alt="btnText">
                <img v-else :src="latestImageUrl" :alt="btnText">
                <!--/アイコン-->
                <!--バッジ-->
                <div v-if="membership.isMini">
                    <!--未保存プリ数-->
                    <span v-if="unsavedPuriCount > 0" class="badge">{{this.unsavedPuriCount}}</span>
                </div>
                <!--バッジ-->
            </a>
        </div>
        <!--/ログイン時-->
    </div>
</template>

<script>
    import * as urlUtil from "../../util/UrlUtil";
    import {getLatestImageUrl} from "../../util/latestImageUrlUtil.js";
    import {getClickAllEvent} from "../../util/Ga4EventUtil";
    import {addQueryData} from "../../util/Ga4EventUtil";
    import Membership from "../../view-model/MembershipViewModel";
    import {headerType} from "../../view-model/enum/HeaderType";

    export default {
        name: "HeaderBtn",
        data() {
            return {
                latestImageUrl: getLatestImageUrl(this.latestImageId)
            }
        },
        props: {
            membership: {
                type: Membership,
                required: true
            },
            headerType: {
                type: Symbol,
                required: true
            },
            latestImageId: {
                type: String,
                required: false
            },
            unsavedPuriCount: {
                type: String,
                required: false
            }
        },
        methods: {
            ga4ClickEvent() {
                getClickAllEvent(this.btnUrl, this.btnText)
            }
        },
        computed: {
            btnUrl() {
                if(this.membership.isGuest) {
                    if(this.headerType === headerType.ALL) {
                        return urlUtil.servletUrlUtil.loginUrl()+addQueryData('links', 'tab')
                    } else {
                        return urlUtil.servletUrlUtil.registrationUrl()
                    }
                } else {
                    /**
                     * MypageNavigationCategoryでパスが組み立てられるため、そこに到達した時点でここのaddQueryDataは無効になってしまう
                     * TODO:遷移後もaddQueryDataを有効にする方法があれば適用したい
                     */
                    return urlUtil.servletUrlUtil.myPageUrl()+addQueryData('links', 'tab')
                }
            },
            btnText() {
                if(this.membership.isGuest) {
                    if(this.headerType === headerType.ALL) {
                        return 'ログイン / 会員登録'
                    } else {
                        return '無料登録'
                    }
                } else {
                    return 'マイページ'
                }
            }
        }
    }
</script>