import moment from 'moment'
import TimeTripViewModel from "./TimeTripViewModel";
/**
 * ユーザのプロフィールを表現するview model.
 */
export default class UserProfileViewModel extends TimeTripViewModel {
    /**
     * @param data APIから受け取ったoutputData
     * @param _ TimeTripを利用するために必要なAccountStatus (このclassからは直接利用しないのであえて名前をつけていない)
     */
    constructor(data, _) {
        super(data, _);

        this.nickname = (data.nickname != null) ? data.nickname : 'ゲストさん';
        this.profileImageUrl = data.profileImageUrl;
        this.birthday = moment(new Date(data.birthday),'YYYY-MM-DD');
        this.gender = data.gender;
        this.prefectureName = data.prefectureName;
    }

    /**
     * 生年月日のデータを整形して返す
     */
    get dateOfBirth() {
        return this.birthday.format('YYYY年M月D日')
    }

    // 25歳以上か？
    get is25yearsAndOver() {
        const dateOfBirth = this.birthday; // 生年月日
        const today = this.timeTripDate.nowDateTime; // 今日の日付
        // 西暦を比較して年齢を算出
        let baseAge = today.year() - dateOfBirth.year();
        // 誕生日を作成
        const birthday = moment(
            new Date(today.year() + "-" + (dateOfBirth.month() + 1) + "-" + dateOfBirth.date())
        );
        // 今日が誕生日より前の日付である場合、算出した年齢から-1した値を返す
        // 今日が誕生日 または 誕生日を過ぎている場合は算出した年齢をそのまま返す
        if (today.isBefore(birthday)) {
            baseAge = baseAge - 1;
        }
        return baseAge >= 25;
    }
}
