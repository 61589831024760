/**
 * フッタータイプを表すENUM
 * TODO:allタイプはVue未実装のため、実装時には追加が必要
 */
const footerType = Object.freeze({
    ALL: Symbol('all'),
    PRIMARY: Symbol('primary'),
    COPYRIGHTONLY: Symbol('copyrightOnly')
});

export { footerType };