import moment from 'moment'

// TimeTripに関係ない箇所での日付操作Utilです。
// TimeTripを考慮したい場合はDateInterceptorを利用してください

/**
 * 指定日時から現在時刻までの日数をカウントする
 * （未保存プリの保存期限の取得時に使われる想定）
 *
 *        ,___________________________
 *        |                           \
 *  ------g---!---!---!---!---!---!---e----- | g:画像がサーバに来た日時 e:保存期限
 *                    |   |   |   |   |
 *             ex)   3/6 3/7 3/8 3/9 3/10
 *                      ^
 *                     now (あと3日)
 *                                  ^
 *                                 now (あと0日)
 *
 * @param {moment} date 指定したい日時(上の図のeにあたる) ※APIから受け取ったexpiringAt
 * @return {Number} 現在時刻からe(date)までの日数
 */
export function getCountDayFromNow(date) {
    const diff = moment.duration(date.diff(moment()));
    return Math.floor(diff.asDays());
}


/**
 * 指定日時が現在時刻より前かを判定する.
 * 境界値は含まない.
 *
 *  ______________
 *                \
 * ---------------e----- | e:指定日時
 *
 * @param {string} date 指定したい日時(上の図のeにあたる)
 * @return {boolean}
 */
export function isBeforeNow(date) {
    const now = moment();
    const target = moment(date, 'YYYY-MM-DDTHH:mm:ss');
    return target.isBefore(now);
}

/**
 * 指定日時が現在時刻以前かを判定する.
 * 境界値を含む.
 *
 *  ______________
 *                |
 * ---------------e----- | e:指定日時
 *
 * @param {string} date 指定したい日時(上の図のeにあたる)
 * @return {boolean}
 */
export function isSameOrBeforeNow(date) {
    const now = moment();
    const target = moment(date, 'YYYY-MM-DDTHH:mm:ss');
    return target.isSameOrBefore(now);
}

/**
 * 現在時刻が指定日時の範囲内かどうかを判定する.
 * 開始日は境界値を含むが、終了日は境界値を含まない.
 *
 *        ,___________________________
 *        |                           \
 *  ------g---------------------------e----- | g:開始日時 e:終了日時
 *
 * @param {string} start  指定したい開始日時(上の図のgにあたる)
 * @param {string} end   指定したい終了日時(上の図のeにあたる)
 * @return {boolean}
 */
export function isInTime(start, end) {
    const now = moment();

    const startAt = moment(start, 'YYYY-MM-DDTHH:mm:ss');
    const endAt = moment(end, 'YYYY-MM-DDTHH:mm:ss');
    return startAt.isSameOrBefore(now) && endAt.isAfter(now)
}

/**
 * 指定時刻が当日の0時以降かどうかを判定する
 *
 *        ,_____________________
 *        |
 *  ------g------------------------- | g:当日の0時
 *
 * @param {string} date 比較したい日付
 * @return {boolean}
 */
export function isOnTheDay(date) {
    const now = moment();
    const target = moment(date, 'YYYY-MM-DDTHH:mm:ss');
    return now.startOf('day').isBefore(target) || now.startOf('day').isSame(target)
}

/**
 * 指定された書式で日付をフォーマットする
 * @param target フォーマットしたいDateオブジェクト
 * @param format フォーマット形式
 */
export function getDateString(target, format) {
    return moment(target).format(format);
}

/**
 * 曜日を取得する
 * @param target
 * @return {string}
 */
export function getDayOfWeek(target) {
    const date = moment(target);
    const dayArray = ["日","月","火","水","木","金","土"];
    const dayNum = date.format("d");
    return dayArray[dayNum];
}

/**
 * 月で丸めた日付を取得する
 * @param target
 * @returns {moment.Moment}
 */
export function getMonth(target) {
    return moment(target).startOf('month');
}

/**
 * 現在時刻を月で丸めた日付を取得する
 * @returns {moment.Moment}
 */
export function getCurrentMonth() {
    return getMonth(moment())
}

/**
 * 指定日時から-1日した日付を返す
 * @param target N日の任意の時間
 * @return N-1日
 */
export function calcDayBefore(target) {
    const date = moment(target);
    return date.subtract(1, 'days').format('M月D日');
}

/**
 * 指定日時(N日0時)までの訴求をする場合に表示するテキストを生成する
 * @param target N日0時
 * @return N-1日の23時59分
 */
export function getLimitText(target) {
    return moment(target).subtract(1, 'seconds').format('M月D日 H:m')
}


/**
 * 二つの日付を比較する
 * T1がT2より過去の場合はtrueを返す
 * @param target1
 * @param target2
 */
export function isBeforeDate(target1, target2) {
    const T1 = moment(target1);
    const T2 = moment(target2);
    return T1.isBefore(T2)
}

/**
 * stringを規定のフォーマットでmoment型に変換する
 * @param date {string}
 * @return {moment.Moment}
 */
export function getMoment(date) {
    return moment(date, 'YYYY-MM-DDTHH:mm:ss');
}

/**
 * moment型を"YYYYMMDD"方式(基本表記)に変換する
 * @param date {moment.Moment}
 * @return {String}
 */
export function getBasicMoment(date) {
    return getMoment(date).format('YYYYMMDD');
}

/**
 * stringを日本語表記('YYYY年M月D日')に変換する
 * @param date {string}
 * @return {String}
 */
export function getJapaneseDate(date) {
    return getMoment(date).format('YYYY年M月D日');
}


/**
 * 指定した日時に日を足す
 * @param date {string}
 * @param daysAfter {number}
 * @return {String}
 */
export function getDaysAfter(date,daysAfter) {
    const m = moment(date).add(daysAfter, "day")
    return m.format('YYYY-MM-DDTHH:mm:ss');
}
