import axios from 'axios'

const errorResponsePictlinkApiV2 = axios.create({
    withCredentials: true
});
/**
 * 基本通信仕様に則ったAPIのエラーハンドリングの共通処理
 * AxiosInterceptorV2で対応できないコンポーネント側でエラーハンドリングが必要な場合に使う
 */
errorResponsePictlinkApiV2.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    //コンポーネント側でエラーハンドリングが必要なため、errorをそのまま返す
    throw error
});

export default errorResponsePictlinkApiV2;
