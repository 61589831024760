<template>
    <h1 class="header-logo">
        <a class="header-logo-link" :class="isLogoOnlyDisabled ? 'is-disabled' : ''" :href="topUrl" data-jest="active-btn" @click="ga4ClickEvent">
            <!--TODO:期間限定ロゴ未実装のため必要な場合は分岐を追加（logo.vmを参照）-->
            <img src="/img/logo.png" :alt="logoAlt">
        </a>
    </h1>
</template>

<script>
    import {getClickAllEvent} from "../../util/Ga4EventUtil";
    import {addQueryData} from "../../util/Ga4EventUtil";
    import {headerType} from "../../view-model/enum/HeaderType";

    export default {
        name: "HeaderLogo",
        props: {
            headerType: {
                type: Symbol,
                required: true
            }
        },
        data() {
            return {
                topUrl: '/sp/top/information'+addQueryData('links','logo'), // ログイン済みで/top/menuにリダイレクトされる場合はaddQueryData取れない
                logoAlt: 'フリューのプリ画取得サイト ピクトリンク'
            }
        },
        methods: {
            ga4ClickEvent() {
                getClickAllEvent(this.topUrl, this.logoAlt)
            }
        },
        computed: {
            isLogoOnlyDisabled() {
                return this.headerType === headerType.LOGOONLYDISABLED
            }
        }
    }
</script>

<style lang="scss" scoped>
.is-disabled {
    pointer-events: none;
}
</style>