import axios from 'axios'

const S3Json = axios.create();
/**
 * S3管理jsonの共通処理
 */
S3Json.interceptors.response.use(
    function(response) {
        return response.data
    },
    function(error) {
        console.log(error)
    }
);
export default S3Json
