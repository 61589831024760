<template>
    <header v-if="!isAppli" class="l-header" :class="{'header-sticky' : isSticky}">
        <!-- FIXME: VOYAGERFRONT-5730でisAppli判定をBaseLayoutへ移す -->
        <div class="header js-header">
            <div class="header-inner js-headerTop">
                <header-logo :headerType="headerType"/>
                <div v-if="isAll || isFreeRegistrationBtn">
                    <div v-if="getMembership">
                        <header-btn :membership="getMembership" :header-type="headerType" :unsaved-puri-count="unsavedPuriCount" :latest-image-id="latestImageId"/>
                    </div>
                </div>
            </div>
            <div v-if="isAll">
                <header-menu />
            </div>
        </div>
        <div v-if="isTimeTripEnabled">
            <toast message="タイムトリップが設定されています" :visible="isTimeTripInfoToastVisible" @close="handleTimeTripInfoToastClose"/>
        </div>
    </header>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import HeaderBtn from '../atoms/HeaderBtn.vue'
import HeaderLogo from '../atoms/HeaderLogo.vue'
import HeaderMenu from '../atoms/HeaderMenu.vue'
import {headerType} from "../../view-model/enum/HeaderType";
import UserAgentUtil from "../../util/UserAgentUtil.js";
import Toast from "../atoms/Toast.vue";

export default {
    name: "TheHeader",
    components: {
        Toast,
        HeaderBtn, HeaderLogo, HeaderMenu
    },
    data() {
        return {
            userAgent: new UserAgentUtil(),
            /**
             * タイムトリップ設定情報を表示するかどうか
             */
            isTimeTripInfoToastVisible: true,
        }
    },
    props: {
        headerType: {
            type: Symbol,
            required: true
        },
        /**
         * vm経由でtoolboxから情報を取得している
         * 相応のAPIができればこちらのpropsは不要
         */
        unsavedPuriCount: {
            type: String,
            required: false
        },
        latestImageId: {
            type: String,
            required: false
        },
        /**
         * メニューボタンがスクロールについてくるようにするか
         */
        isSticky: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        ...mapGetters(
            'AccountStoreV2', ['isAppli', 'accountStatus']
        ),
        getMembership() {
            return this.accountStatus && this.accountStatus.membership ? this.accountStatus.membership : null
        },
        isAll() {
            return this.headerType === headerType.ALL
        },
        isFreeRegistrationBtn() {
            return this.headerType === headerType.FREEREGISTRATIONBTN
        },
        /**
         * タイムトリップが設定されているかどうか
         */
        isTimeTripEnabled() {
            return !!this.accountStatus?.isTimeTripEnabled
        }
    },
    methods: {
        ...mapActions({
            getIsAppli: 'AccountStoreV2/getIsAppli'
        }),
        /**
         * タイムトリップ情報トーストを閉じる
         */
        handleTimeTripInfoToastClose() {
            this.isTimeTripInfoToastVisible = false
        },
    },
    mounted() {
        this.getIsAppli(this.userAgent.isAppli)
    }
}
</script>
