
const state = {
    mailAddress: null,
}

const getters = {
    mailAddress(state) {
        return state.mailAddress
    },
}

const actions = {
    updateMailAddress({ commit, state }, value) {
        commit('setMailAddress', value);
    },
}

const mutations = {
    setMailAddress(state, mailAddress) {
        state.mailAddress = mailAddress;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}