import axios from 'axios'
import ApiResponseOutputData from "../output-data/ApiResponseOutputData";

const pictlinkApi = axios.create({
    withCredentials: true
});
/**
 * 基本通信仕様に則ったAPIのエラーハンドリングの共通処理
 */
pictlinkApi.interceptors.response.use(function (response) {
    let result = new ApiResponseOutputData(response.data)
    if(result.error) {
        switch (result.error.code) {
            case 120: // 未ログインエラー
                //location.href = urlUtil.servletUrlUtil.loginUrl();
                break;
            default:
                break;
        }
    }

    // TimeTrip情報がある場合はentityにフィールドを追加する
    if(result.clock) {
        result.entity ? result.entity.clock = result.clock : result.entity = { "clock": result.clock }
    }
    return result.entity;
}, function (error) {
    console.log(error)
});

export default pictlinkApi
