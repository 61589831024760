import moment from "moment";

/**
 * APIの基本仕様に基づくResponse bodyのjsonを扱うクラス.
 */
export default class ApiResponseOutputData {
    /**
     * @param data API基本仕様に基づくjsonフィールド.
     */
    constructor(data) {
        this.entity = data.entity;
        this.error = (data.error != null) ? new ApiResponseError(data.error) : null;
        this.clock = (data.clock != null) ? new moment(data.clock) : null;
    }
}

class ApiResponseError {
    /**
     * @param data サーバーエラーを表すjsonフィールド.
     */
    constructor(data) {
        this.code = data.code;
        this.message = data.message;
    }
}
