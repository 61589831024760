<template>
    <h3 class="m-heading-secondary" :class="{'is-primary': isPrimary, 'm-heading-secondary_bold': isBold}">
        <slot></slot>
    </h3>
</template>

<script>
    export default {
        name: "heading-appeal-secondary",
        props: {
            isPrimary: {
                type: Boolean,
                default: false,
                required: false
            },
            isBold: {
                type: Boolean,
                default: false,
                required: false
            }
        }
    }
</script>

<style scoped lang="scss">
    $grid-base: 4.27vw; // 375px幅に対して16px
    $grid-consistent: $grid-base * 0.5;// 375px幅に対して8px
    .m-heading-secondary {
        padding: $grid-consistent;
    }
</style>