/**
 * ユーザのプロフィール情報
 */
export default class UserProfileOutputData {
    constructor(data) {
        this.profileImageUrl = data.profileImageUrl;  // プロフィール画像パス(設定していない場合はデフォルトの画像が返る)
        this.nickname = data.nickname;                // ニックネーム ※設定していない場合はnull
        this.birthday = data.birthday;                // 生年月日
        this.gender = data.gender;                    // 性別(女性 or 男性)
        this.prefectureName = data.prefectureName;    // 都道府県名
        this.imageId = data.imageId;                  // プロフィール画像の画像ID
    }
}