import PuriSealsMachineViewModel from './PuriSealsMachineViewModel'
import PuriSealViewModel from './PuriSealViewModel'
import moment from 'moment';

/**
 * 指定されたシールIDに紐づくプリを取得するAPI(V2)のレスポンスに含まれるプリのentity.
 */
export default class PuriSealsViewModel {
    /**
     * @param data プリのentityを表すjsonフィールド群.
     */
    constructor(data) {
        this.sealId = data.sealId;                                                             // シールID
        this.sealExpireDate = moment(data.sealExpireDate);                                     // シールの保存期限
        this.machine = new PuriSealsMachineViewModel(data.machine);                            // 筐体情報
        this.isProof = data.isProof;                                                           // 証明プリか
        this.puris = data.puris ? data.puris.map(puri => new PuriSealViewModel(puri)) : [];    // プリ画像情報リスト
    }

    // らくがきありのみを抽出する
    get getNotRawPuris() {
        return this.puris.filter((r) => {
            // purisの中に証明プリ(SAMPLE)が含まれている場合があるため、その場合は除外する
            return !r.isRawPuri && !r.isProofSample
        })
    }

    // らくがきなしのみを抽出する
    get getRawPuris() {
        return this.puris.filter((r) => {
            return r.isRawPuri
        })
    }

    get getFreePuris() {
        return this.puris.filter((r) => {
            return !r.isRawPuri
        }).slice(0, 1);
    }

    get getPaidPuris() {
        return this.puris.filter((r) => {
            return !r.isRawPuri
        }).slice(1);
    }

    /**
     * らくがきなし（isRawがtrueのプリ）が含まれるかどうか
     * 含まれる場合はtrueを返す
     *
     * @returns {boolean}
     */
    get isIncludeRaw() {
        const rawPuris = this.puris.map(m => m.isRawPuri)
        return rawPuris.some(s => s === true)
    }

    /**
     * プリの取得可能期間を返す
     *
     * @param accountStatus {AccountStatusViewModel}
     * @returns {number}
     */
    getSealSaveLimitDate(accountStatus) {
        if(accountStatus && accountStatus.timeTripDate && accountStatus.timeTripDate.nowDateTime){
            return this.sealExpireDate.diff(accountStatus.timeTripDate.nowDateTime, 'days')
        } else {
            return this.sealExpireDate.diff(moment(), 'days')
        }
    }
}
