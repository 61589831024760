<template>
    <div class="menu js-fixed-tab">
        <ul class="menu-list">
            <li v-for="(i, index) in menuItem" class="menu-list-item" :key="`m-${index}`">
                <a class="menu-list-link" :class="i.type" :href="i.url" :data-jest="`menu-link-${i.type}`" @click="ga4ClickEvent(i.url, i.text)">
                    <span>{{i.text}}</span>
                </a>
            </li>
            <li class="menu-list-item">
                <a class="menu-list-link map" :href="mapUrl" data-jest="menu-link-map" @click="ga4ClickEvent(mapUrl, mapText)" target="_blank">
                    <span>{{ mapText }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import * as urlUtil from "../../util/UrlUtil";
    import {getClickAllEvent} from "../../util/Ga4EventUtil";
    import {addQueryData} from "../../util/Ga4EventUtil";

    export default {
        name: "HeaderMenu",
        data() {
            return {
                menuItem: [
                    {type: 'puriget', url: '/sp/puri/form_seal_id'+addQueryData('links', 'tab'), text: 'プリGET'}, // ログイン済みで/top/menuにリダイレクトされる場合はaddQueryData取れない
                    {type: 'machine', url: urlUtil.servletUrlUtil.machineListUrl()+addQueryData('links', 'tab'), text: 'プリ機情報'}
                ],
                mapUrl: 'https://map.pictlink.com/'+addQueryData('links', 'tab'),
                mapText: 'プリ機検索'
            }
        },
        methods: {
            ga4ClickEvent(elUrl, elText) {
                getClickAllEvent(elUrl, elText)
            }
        }
    }
</script>