import * as urlUtil from '../../util/UrlUtil'
import errorResponsePictlinkApiV2 from "../interceptor/ErrorResponseAxiosInterceptorV2";
import PrivacyPolicyStatusesOutputData from "../output-data/PrivacyPolicyStatusesOutputData";
import PrivacyPolicyStatusesViewModel from "../../view-model/PrivacyPolicyStatusesViewModel";
import PrivacyPolicyAgreementViewModel from "../../view-model/PrivacyPolicyAgreementViewModel";

/**
 * プライバシーポリシーのStatusを返す
 */
export function getPrivacyPolicyStatuses() {
    return errorResponsePictlinkApiV2.get(urlUtil.apiEndpointUtil.privacyPolicyStatusesLatestApiUrl())
        .then(result => new PrivacyPolicyStatusesOutputData(result.policies))
        .then(result => new PrivacyPolicyStatusesViewModel(result))
        .catch(error => {
            throw error
        });
}

/**
 * ログイン状態にかかわらずCookieに同意フラグを持たせる
 * @param {PrivacyPolicyStatusesViewModel} privacyPolicyStatuses
 */
export function setPrivacyPolicyAgreement(privacyPolicyStatuses) {
    const policies = [privacyPolicyStatuses.servicePolicy, privacyPolicyStatuses.specialContract]
    const apiPath = urlUtil.apiEndpointUtil.setPrivacyPolicyAgreementApiUrl()
    const postValue = {
        "policies": policies.map(p => new PrivacyPolicyAgreementViewModel(p))
    }
    return errorResponsePictlinkApiV2.post(apiPath, postValue)
        .then(function (result) {
            return result
        })
        .catch(error => {
            throw error
        });
}