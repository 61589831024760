/**
 * cssで優先度を指定するためのENUM
 */
const cssPriority = Object.freeze({
    DEFAULT: Symbol('default'),
    PRIMARY: Symbol('primary'),
    SECONDARY: Symbol('secondary'),
    DISABLED: Symbol('disabled')
});

export default class Priority {
    constructor(status) {
        this.enum = Symbol.for(status)
    }

    get isDisabled() {
        return this.enum === Symbol.for(cssPriority.DISABLED.description)
    }
}

export { cssPriority };