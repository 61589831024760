/**
 * SNSペナルティ状態を表す.
 */
export default class PenaltySns {
    /**
     * @param {String} status
     */
    constructor(status) {
        this.status = status;
    }

    get hasPenalty() {
        return this.status !== null;
    }

    get isWarning() {
        return this.status === 'WARNING';
    }

    get isAccesscontrol() {
        return this.status === 'ACCESSCONTROL';
    }

    get isSuspension() {
        return this.status === 'SUSPENSION';
    }
}