/**
 * プリゲットペナルティ状態を示す
 */
export default class PenaltyPuriGet {
    /**
     * @param {String} status
     */
    constructor(status) {
        this.status = status;
    }

    get isRed() {
        return this.status === 'RED';
    }

    get isYellow() {
        return this.status === 'YELLOW';
    }

    get isGreen() {
        return this.status === 'GREEN';
    }
}