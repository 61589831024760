<template>
    <div v-if="visible" class="toast">
        {{ message }}
        <button class="toast-close-button" @click="closeToast">&times;</button>
    </div>
</template>

<script>
/**
 * 画面下部に表示するトーストメッセージ
 * 実装現在一般ユーザに表示しないコンポーネントのため、デザインは最低限のものとしています。ユーザに見せる用途では使用しない想定です
 */
export default {
    name: 'Toast',
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        message: {
            type: String,
            required: true
        }
    },
    methods: {
        closeToast() {
            this.$emit('close');
        }
    }
};
</script>

<style lang="scss" scoped>
@use '../../../scss/v2/base/variables';

.toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 250;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 16px 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 300px;
    max-width: 80%;
    font-size: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    &-close-button {
        background: none;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
    }
}
</style>
