/**
 * elementの高さ・幅の取得に関するUtil
 */

/**
 * 全画面表示する際の高さ
 */
export function getMainContentClientHeight() {
    let documentHeight = document.documentElement.clientHeight;
    let headerElement = document.getElementsByTagName('header');
    let footerElement = document.getElementsByTagName('footer');
    let headerHeight = headerElement[0] ? headerElement[0].clientHeight : 0;
    let footerHeight = footerElement[0] ? footerElement[0].clientHeight : 0;
    return documentHeight - headerHeight - footerHeight
}