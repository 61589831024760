/**
 * アクセスユーザ情報に関するjsonフィールドを表すクラス.(v2対応版)
 */
export default class AccountStatusOutputData {
    constructor(data) {
        this.account = data.account ? new AccountDetailOutputData(data.account) : null; // アカウント情報(アカウントがない場合はnull)
        this.membershipStatus = data.membershipStatus;             // アカウントのmembership
        this.clock = data.clock;               // アカウントのTimeTrip情報(intercepterで二次加工したもの)
    }
}

class AccountDetailOutputData {
    constructor(data) {
        this.id = data.id;                        // アカウントID
        this.protected = data.protected;          // 鍵付きかどうか
        this.canAddon = data.canAddOn;            // 現在の課金に対しアドオン課金できるかどうか(ミニはfalse)
        this.snsPenalty = data.snsPenalty;        // SNSペナルティ状態 null, WARNING, ACCESSCONTROL, SUSPENSION
        this.purigetPenalty = data.purigetPenalty; // プリゲットペナルティの状態 RED, YELLOW, GREEN
        this.official = data.official;             // 公認アカウントかどうか
        this.mailAddress = data.mailAddress;       // アカウントに紐づくメールアドレス
        this.registeredAt = data.registeredAt;       // アカウントの登録日時 (YYYY-MM-DDThh:mm:ss+09:00)
    }
}
