<template>
    <footer v-if="!isAppli" id="global_footer">
        <!-- FIXME: VOYAGERFRONT-5558でisAppli判定をBaseLayoutへ移す -->
        <div>

            <!--フリューのプリ公式 Twiiter-->
            <footer-sns v-if="!isCopyrightOnly"/>

            <!--フッターメニュー-->
            <div v-if="isAll || isPrimary">
                <footer-menu v-if="getMembership" :footer-type="footerType" :membership="getMembership" :is-docomo-payment="isDocomoPayment" :is-official="isOfficial"/>
            </div>

            <!--コピーライト-->
            <footer-copyright :footer-type="footerType"/>
        </div>
    </footer>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FooterSns from "../atoms/FooterSns.vue";
import FooterMenu from "../molecules/FooterMenu.vue";
import UserAgentUtil from "../../util/UserAgentUtil.js";
import FooterCopyright from "../atoms/FooterCopyright.vue";
import {footerType} from "../../view-model/enum/FooterType";

export default {
    name: "TheFooter",
    components: {FooterCopyright, FooterMenu, FooterSns},
    data() {
        return {
            userAgent: new UserAgentUtil()
        }
    },
    props: {
        footerType: {
            type: Symbol,
            required: true
        },
        /**
         * vm経由でtoolboxから情報を取得している
         * 相応のAPIができればこちらのpropsは不要
         */
        isDocomoPayment: {
            type: Boolean,
            required: false
        },
        isOfficial: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        ...mapGetters(
            'AccountStoreV2', ['isAppli', 'accountStatus']
        ),
        getMembership() {
            return this.accountStatus && this.accountStatus.membership ? this.accountStatus.membership : null
        },
        isAll() {
            return this.footerType === footerType.ALL
        },
        isPrimary() {
            return this.footerType === footerType.PRIMARY
        },
        isCopyrightOnly() {
            return this.footerType === footerType.COPYRIGHTONLY
        }
    },
    methods: {
        ...mapActions({
            getIsAppli: 'AccountStoreV2/getIsAppli'
        })
    },
    mounted() {
        this.getIsAppli(this.userAgent.isAppli)
    }
}
</script>
