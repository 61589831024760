import pictlinkApi from '../interceptor/AxiosInterceptor';
import * as urlUtil from '../../util/UrlUtil'
import AccountStatusOutputData from '../output-data/AccountStatusOutputData';
import UserAccessOutputData from "../output-data/UserAccessOutputData";
import AccountStatusViewModel from '../../view-model/AccountStatusViewModel'
import UserAccessClientViewModel from "../../view-model/UserAccessClientViewModel";

/**
 * アクセスユーザのシステム情報を取得する
 * @return {Promise<AccountStatusViewModel>}
 */
export function getAccountStatus() {
    return pictlinkApi.get(urlUtil.apiEndpointUtil.accountV2ApiUrl())
        .then(result =>
            new AccountStatusOutputData(result)
        ).then(result => {
            return new AccountStatusViewModel(result)
        })
}

/**
 * アクセスユーザの端末・回線情報を取得する
 * @return {Promise<UserAccessClientViewModel>}
 */
export function getAccessClient() {
    return pictlinkApi.get(urlUtil.apiEndpointUtil.accessClientApiUrl())
        .then(result =>
            new UserAccessOutputData(result)
        ).then(result => {
            return new UserAccessClientViewModel(result)
        })
}