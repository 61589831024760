import pictlinkApi from '../interceptor/AxiosInterceptor';
import * as urlUtil from '../../util/UrlUtil'
import FuryuBannerOutputData from '../output-data/FuryuBannerOutputData'
import FuryuBannerViewModel from '../../view-model/FuryuBannerViewModel'

/**
 * Furyu Banner取得APIを呼ぶ.
 * @param {string} spaceId バナーを掲載する広告枠(場所)を示すID
 */
export function getFuryuBanner(spaceId) {
    return pictlinkApi.get(urlUtil.apiEndpointUtil.furyuBannerApiUrl(spaceId))
        .then(result =>
            new FuryuBannerOutputData(result)
        ).then(result => {
            return new FuryuBannerViewModel(result)
        })
}