<template>
    <h2 class="m-heading-section is-full-width" :class="[{'is-full-height': isPrimary}, {'m-heading-section_recommend': isRecommend}]">
        <span class="m-heading-section-ttl">{{ title }}</span>
    </h2>
</template>

<script>
    export default {
        props: {
            /**
             * 見出しにするテキスト
             */
            title: {
                type: String,
                required: true
            },
            /**
             * ページの最上部かどうか
             */
            isPrimary: {
                type: Boolean,
                required: false,
                default: true
            },
            /**
             * おすすめリボンを付けるかどうか
             */
            isRecommend: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>

<style scoped lang="scss">
@use "../../../scss/v2/modules/heading";
    $grid-base: 4.27vw; // 375px幅に対して16px
    $grid-consistent: $grid-base * 0.5;// 375px幅に対して8px
    .m-heading-section {
        padding: $grid-consistent;
        &-ttl {
            display: block;
        }
    }
</style>
