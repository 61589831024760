import * as newsAction from "../action/NewsAction";

const state = {
    newsList: null,
}

const getters = {
    newsList(state) {
        return state.newsList
    },
}

const actions = {
    async fetchNewsList({ commit }, newsCategoryId) {
        commit('setNewsList', await newsAction.fetchNewsList(newsCategoryId));
    },
}

const mutations = {
    setNewsList(state, newsList) {
        state.newsList = newsList;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}