<template>
    <div class="comments">
        <small v-if="!isCopyrightOnly" class="comments-copyright">会社名、製品名、サービス名等は、それぞれ各社の商標または登録商標です。</small>
        <small class="comments-copyright">© FURYU Corporation. All Rights Reserved.</small>
    </div>
</template>

<script>
import {footerType} from "../../view-model/enum/FooterType";

export default {
    name: "FooterCopyright",
    props: {
        footerType: {
            type: Symbol,
            required: true
        }
    },
    computed: {
        isCopyrightOnly() {
            return this.footerType === footerType.COPYRIGHTONLY
        }
    }
}
</script>