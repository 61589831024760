/**
 * アクセスユーザの購読解除可能な決済リストを取得する。 アプリ課金であっても（サイト上からは退会できないが）返却する。
 */

export default class PaymentUnsubscribableOutputData {
    constructor(data) {
        this.unsubscribeUrl = data.unsubscribeUrl; // 退会時に使用するURLのパス
        this.accessMethod = data.accessMethod; // 退会URLにアクセスする際のメソッド(GET/POST)
        this.name = data.name; // 表示名
    }

}