import moment from 'moment'
import * as dateUtil from '../util/DateUtil'
import * as urlUtil from '../util/UrlUtil'

/**
 * プリを表すview model.
 */
export default class PuriViewModel {
    constructor(data) {
        this.imageId = data.imageId;
        this.sealId = data.sealId;
        this.puriNumber = data.puriNumber;
        this.expiringAt = data.expiringAt;
        this.rawPuri = data.rawPuri;
        this.machineName = data.machineName;
        this.readable = data.readable;
    }

    /**
     * 当該プリが未保存プリかどうか
     * @return {boolean}
     */
    get isUnsavedPuri() {
        return !this.imageId;
    }

    /**
     * 当該プリがらくがきなしプリかどうか
     * @return {boolean}
     */
    get isRawPuri() {
        return this.rawPuri;
    }

    /**
     * 閲覧可能かどうか
     */
    get isReadable() {
        return this.readable;
    }

    /**
     * 未保存プリの場合、保存期限までの日数を返す.
     * @return {Number}
     */
    get expiringDateCount() {
        if(this.isUnsavedPuri) {
            const target = moment(this.expiringAt, 'YYYY-MM-DD');
            return dateUtil.getCountDayFromNow(target);
        }
    }

    get unsavedThumbUrl() {
        return urlUtil.imageUrlUtil.unsavedPuriThumbUrl(this.sealId, this.puriNumber)
    }
}
