/**
 * 指定されたシールIDに紐づくプリを取得するAPI(V2)のレスポンスに含まれるプリ単体情報のentity.
 */
export default class PuriSealViewModel {
    /**
     * @param data プリ単体のentityを表すjsonフィールド群.
     */
    constructor(data) {
        this.number = data.number;                              // プリ番号
        this.thumbnailUrl = data.thumbnailUrl;                  // サムネイル画像URL
        this.qvgaUrl = data.qvgaUrl;                            // 拡大画像URL
        this.available = data.available ? data.available : [];  // このプリを取得可能なmembership
        this.isRawPuri = data.isRawPuri;               // らくがきなし画像かどうか
        this.rawPuriImageNumber = data.rawPuriImageNumber;   // 対応するらくがき有りプリ番号
        this.isProofSample = data.isProofSample;                // 証明プリのSAMPLE画像かどうか
    }
}