import PuriOutputData from './PuriOutputData'

/**
 * ページングされたプリ一覧のjsonフィールドを表すクラス.
 */
export default class PuriListWithPaginationOutputData {
    constructor(data) {
        this.totalCount = data.totalCount;                          // 全画像数
        this.currentPage = data.currentPage;                        // 現在のページ番号
        this.totalPage = data.totalPage;                            // 総ページ数
        this.items = data.items.map(r => new PuriOutputData(r));    // プリのアイテム群
    }
}