import * as dateUtil from '../util/DateUtil'

export default class PaymentViewModel {
    constructor(data){
        this.kindId = data.kindId;
        this.paymentAt = data.paymentAt;
    }

    /**
     * 当月入会かどうか
     * @returns {boolean}
     */
    get isJoinThisMonth() {
        const currentMonth = dateUtil.getCurrentMonth();
        const paymentMonth = dateUtil.getMonth(this.paymentAt);
        return paymentMonth.diff(currentMonth, 'month') === 0
    }
}