import * as furyuBannerAction from '../../model/action/FuryuBannerAction'

/**
 * フリューバナーの情報を管理するStore
 */
const state = {
    pictlinkMaintenance: null,
    pictlinkQuestionnaire: null,
    furyuInformation: null,
    pictlinkInformation: null,
    informationPictlinkPhotos: null
};

const getters = {
    pictlinkMaintenance(state) {
        return state.pictlinkMaintenance
    },
    pictlinkQuestionnaire(state) {
        return state.pictlinkQuestionnaire
    },
    furyuInformation(state) {
        return state.furyuInformation
    },
    pictlinkInformation(state) {
        return state.pictlinkInformation
    },
    informationPictlinkPhotos(state) {
        return state.informationPictlinkPhotos
    }
};

const mutations = {
    setPictlinkMaintenance(state, pictlinkMaintenance) {
        state.pictlinkMaintenance = pictlinkMaintenance
    },
    setPictlinkQuestionnaire(state, pictlinkQuestionnaire) {
        state.pictlinkQuestionnaire = pictlinkQuestionnaire
    },
    setFuryuInformation(state, furyuInformation) {
        state.furyuInformation = furyuInformation
    },
    setPictlinkInformation(state, pictlinkInformation) {
        return state.pictlinkInformation = pictlinkInformation
    },
    setInformationPictlinkPhotos(state, informationPictlinkPhotos) {
        return state.informationPictlinkPhotos = informationPictlinkPhotos
    }
};

const actions = {
    getBannerHtml({commit}, payload) {
        return furyuBannerAction.getFuryuBanner(payload.spaceId).then(r => payload.spaceState = r.bannerHtml)
    },
    async getPictlinkMaintenance ({commit, dispatch}) {
        commit('setPictlinkMaintenance',
            await dispatch('getBannerHtml',{spaceId:'pictlink_sp_maintenance_lead', spaceState: state.pictlinkMaintenance}))
    },
    async getPictlinkQuestionnaire ({commit, dispatch}) {
        commit('setPictlinkQuestionnaire',
            await dispatch('getBannerHtml',{spaceId: 'pictlink_sp_questionnaire_lead', spaceState: state.pictlinkQuestionnaire})
        )
    },
    async getFuryuInformation ({commit, dispatch}) {
        commit('setFuryuInformation',
            await dispatch('getBannerHtml',{spaceId: 'furyu_information1', spaceState: state.furyuInformation})
        )
    },
    async getPictlinkInformation ({commit, dispatch}) {
        commit('setPictlinkInformation',
            await dispatch('getBannerHtml',{spaceId: 'pictlink_sp_information', spaceState: state.pictlinkInformation})
        )
    },
    async getInformationPictlinkPhotos ({commit, dispatch}) {
        commit('setInformationPictlinkPhotos',
            await dispatch('getBannerHtml',{spaceId: 'pictlink_sp_pictlink_photo', spaceState: state.informationPictlinkPhotos})
        )
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
