<template>
    <div :style="setBoxHeight" class="c-scroll-box" data-jest="scroll-box">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ScrollBox",
    props: {
        /**
         * スクロールボックスの高さ
         */
        boxHeight: {
            type: String,
            required: false,
            default: '168'
        }
    },
    computed: {
        setBoxHeight() {
            return {
                'height': `${this.boxHeight}px`,
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@use '../../../scss/v2/base/variables';
.c-scroll-box {
    border: 1px solid variables.$color-pictlink-fog-25;
    height: 168px;
    overflow-y: scroll;
}
</style>
