export default class PaymentTrialViewModel {
    constructor(data, trialJudge, puriGetComplete, myPage, puriGet) {
        this.canAdmit = data.canAdmit; // 物理的にお試し可能かどうか
        this.endDate = data.endDate;

        // UFOでお試しを許されているかどうか
        this.isTrialPermitted = trialJudge ? trialJudge.isTrialPermitted : false;
        // プリGET完了画面でお試し導線を表示可能かどうか
        this.isShowPuriGetComplete = puriGetComplete ? puriGetComplete.isTrialPermitted : false;
        // マイページでお試し導線を表示可能かどうか
        this.isShowMyPage = myPage ? myPage.isTrialPermitted : false;
        // プリGetLPでお試し導線を表示可能かどうか
        this.isShowPuriGet = puriGet ? puriGet.isTrialPermitted : false;

        //お試しできない理由(できる場合はnull)
        this.reason = data.reason;
        if(!this.isTrialPermitted) {
            // UFOで許されていない場合は理由を上書きする
            this.reason = 'UFO_DENIED'
        }
    }

    /**
     * お試しを許されている かつ 物理的にもお試しできるかどうか
     */
    get canTrial() {
        return this.canAdmit && this.isTrialPermitted
    }
}