import pictlinkApiV2 from '../interceptor/AxiosInterceptorV2';
import * as urlUtil from '../../util/UrlUtil';
import PuriSealsOutputData from "../output-data/PuriSealsOutputData";
import PuriSealsViewModel from "../../view-model/PuriSealsViewModel";

/**
 * 指定されたシールIDに紐づくプリを取得するAPIをコールする.
 * @param {String} sealId シールID
 */
export function getPuriSeals(sealId) {
    return pictlinkApiV2.get(urlUtil.apiEndpointUtil.puriSealsApiUrl(sealId))
        .then(result =>{
            return new PuriSealsOutputData(result)
        }).then(result =>{
            return new PuriSealsViewModel(result)
        })
}

/**
 * 指定されたシールIDに紐づくプリを取得するAPIをコールする.
 * (基本getPuriSealsと同じだがcatchの時にerrorオブジェクトを返すver)
 * @param {String} sealId シールID
 */
export function getPuriSealsV2(sealId) {
    return pictlinkApiV2.get(urlUtil.apiEndpointUtil.puriSealsApiUrl(sealId))
        .then(result =>{
            return new PuriSealsOutputData(result)
        }).then(result =>{
            return new PuriSealsViewModel(result)
        })
        .catch(error =>{
            // FIXME: 存在しないsealIdでsealsを取得しようとした場合,
            //  APIのエラーコードがここでは返せないので暫定対応として
            //  コンポーネント側でエラーハンドリングできるようエラーオブジェクトを返す
            return {
                error: true
            }
        });
}
