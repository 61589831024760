const state = {
    navigation: {
        category: 'puri',
        order: 'new'
    }
}

const getters = {
    navigation(state) {
        return state.navigation
    }
}

const actions = {
    getNavigation({ commit, state }, value) {
        commit('setNavigation', value);
    },
}

const mutations = {
    setNavigation(state, navigation) {
        state.navigation = navigation
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}