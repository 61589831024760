import moment from 'moment'

/**
 * TimeTripに対応した日付操作関数を提供する.
 * TimeTripしている時はその時刻をnowとして扱い、していなければ普通にnew Dateする.
 * @param clock {moment}
 *
 */
export default function DateInterceptor(clock) {
    this.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';
    this.nowDateTime = clock ? clock : new moment();
}

/**
 * 現在時刻(TimeTrip考慮済)が指定時刻内かどうかを判定する
 * 開始日は境界値を含むが、終了日は境界値を含まない
 *
 *        ,___________________________
 *        |                           \
 *  ------g---------------------------e----- | g:開始日時 e:終了日時
 *
 * @param start {String}　開始日時(上の図のgにあたる)
 * @param end {String}　終了日時(上の図のeにあたる)
 */
DateInterceptor.prototype.isInTime = function (start, end) {
    const startAt = moment(start, this.defaultFormat);
    const endAt = moment(end, this.defaultFormat);
    return startAt.isSameOrBefore(this.nowDateTime) && endAt.isAfter(this.nowDateTime);
}

/**
 * 現在時刻(TimeTrip考慮済)が指定時刻以降かどうかを判定する
 *
 *        ,_____________________
 *        |
 *  ------g------------------------- | g:開始日時
 *
 * @param start {String}　開始日時
 */
DateInterceptor.prototype.isAfterTime = function (start) {
    const target = moment(start, this.defaultFormat);
    return target.isSameOrBefore(this.nowDateTime);
}
