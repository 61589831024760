import pictlinkApiV2 from "../interceptor/AxiosInterceptorV2";
import * as urlUtil from '../../util/UrlUtil';
import PaymentUnsubscribableOutputData from "../output-data/PaymentUnsubscribableOutputData";
import PaymentUnsubscribableViewModel from "../../view-model/PaymentUnsubscribableViewModel";

/**
 * アクセスユーザの購読解除可能な決済リストを取得するAPIを呼ぶ.
 *
 */
export function getPaymentUnsubscribable(accountStatus) {
    if(accountStatus && accountStatus.membership && !accountStatus.membership.isGuest) {
        const apiPath = urlUtil.apiEndpointUtil.paymentUnsubscribableApiUrl()
        return pictlinkApiV2.get(apiPath)
            .then(result => {
                return result.map(p => new PaymentUnsubscribableOutputData(p))
            }).then(result => {
                return result.map(p => new PaymentUnsubscribableViewModel(p))
            })
    } else {
        return null
    }
}
