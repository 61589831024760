
export default class PictlinkAdViewModel {
    constructor(data) {
        this.id = data.id;
        this.conversionId = data.conversionId;
        this.name = data.name;
        this.linkUrl = data.linkUrl;
        this.linkType = data.linkType;
        this.title = data.title;
        this.text = data.text;
        this.mediaUrl = data.mediaUrl;
        this.mediaType = data.mediaType;
    }
}