import pictlinkApi from '../interceptor/AxiosInterceptor';
import * as urlUtil from '../../util/UrlUtil';

import PuriListWithPaginationOutputData from "../output-data/PuriListWithPaginationOutputData";
import UnsavedPuriViewModel from "../../view-model/UnsavedPuriViewModel";
import PuriOutputData from "../output-data/PuriOutputData";
import PuriViewModel from "../../view-model/PuriViewModel";

const showUnsavedPuriNumber = 10;

/**
 * ユーザの未保存プリを最大10件取得するAPIをコールする.
 * @param {Membership} membership ユーザのメンバーシップ状況
 */
export function getUnsavedPuri(membership) {
    if(membership.isMini || membership.isStandard) {
        let isIncludePuri = false;
        let isIncludeRaw = false;

        // メンバーシップによって所持未保存プリの種類が変わる
        if(membership.isMini) {
            isIncludePuri = true
        } else if(membership.isStandard) {
            isIncludeRaw = true
        }

        return pictlinkApi.get(urlUtil.apiEndpointUtil.unsavedPuriApiUrl(showUnsavedPuriNumber, isIncludePuri, isIncludeRaw))
            .then(result =>
                new PuriListWithPaginationOutputData(result)
            ).then(result => {
                return new UnsavedPuriViewModel(result)
            })
    } else {
        // Guest(未ログイン)またはプレミアム状態のときは未保存は無いのでnullを返す
        return new UnsavedPuriViewModel(null)
    }
}

/**
 * シールIDに紐づく未保存プリを取得するAPIをコールする.
 * @param {String} sealId シールID
 * @param {Boolean} isIncludePuri
 * @param {Boolean} isIncludeRaw
 */
export function getUnsavedPuriBySealId(sealId, isIncludePuri, isIncludeRaw) {
    return pictlinkApi.get(urlUtil.apiEndpointUtil.unsavedSealPuriApiUrl(sealId, isIncludePuri, isIncludeRaw))
        .then(result =>
            result.map(r => new PuriOutputData(r))
        ).then(result => {
            return result.map(r => new PuriViewModel(r))
        })
}