import axios from 'axios'

const pictlinkApiV2 = axios.create({
    withCredentials: true,
});
/**
 * 基本通信仕様に則ったAPIのエラーハンドリングの共通処理
 */
pictlinkApiV2.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    //全API共通のエラー処理はここに実装
    console.log(error);
    // Should return error, as it may contain message or error-code for users.
    return error;
});

export default pictlinkApiV2