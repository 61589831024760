import * as pictlinkAdAction from "../action/PictlinkAdAction";

const state = {
    myPageAppealMeerkatAdList: null,
    topPageAdList: null,
}

const getters = {
    myPageAppealMeerkatAdList(state) {
        return state.myPageAppealMeerkatAdList
    },
    topPageAdList(state){
        return state.topPageAdList
    }

}

const actions = {
    async getMyPageAppealMeerkatAdList({ commit }) {
        const spaceId = await pictlinkAdAction.getPictlinkAdSpaceId('myPageAppealMeerkat')
        commit('setMyPageAppealMeerkatAdList', await pictlinkAdAction.fetchPictlinkAdList(spaceId));
    },
    async getTopPageAdList({ commit }){
        const spaceId = await pictlinkAdAction.getPictlinkAdSpaceId('topPage')
        commit('setTopPageAdList', await pictlinkAdAction.fetchPictlinkAdList(spaceId));
    },
}

const mutations = {
    setMyPageAppealMeerkatAdList(state, myPageAppealMeerkatAdList) {
        state.myPageAppealMeerkatAdList = myPageAppealMeerkatAdList;
    },
    setTopPageAdList(state, topPageAdList) {
        state.topPageAdList = topPageAdList;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}