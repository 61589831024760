export default class MobileNetworkViewModel {
    constructor(network) {
        this.network = network === null ? 'wifi' : network.toLowerCase();
    }

    get isWifi() {
        return this.network === 'wifi'
    }

    get isAu() {
        return this.network === 'ezweb'
    }

    get isDocomo() {
        return this.network === 'docomo'
    }

    get isSoftbank() {
        return this.network === 'softbank'
    }

    get isCarrier() {
        return this.isAu || this.isDocomo || this.isSoftbank
    }

    get canTrial() {
        return this.isAu
    }
}