/**
 * chrome70未満でdescriptionが使用できないためPolyfill
 */
const description = require('symbol.prototype.description');
if(Symbol('').description === undefined) {
    description.shim();
}

/**
 * safari12.1未満でfromEntriesが使用できないためPolyfill
 */
const fromEntries = require('object.fromentries');
if(Object.fromEntries === undefined) {
    fromEntries.shim();
}

/**
 * 登録しているアカウントの会員種別を表すenum
 */
const ACCOUNT = Object.freeze({
    MINI: Symbol('無料会員') ,
    STANDARD: Symbol('有料会員'),
    PREMIUM: Symbol('有料会員プラス'),
});

/**
 * 全ての登録しているアカウントを取得
 */
const AllAccountEnum = Object.entries(ACCOUNT).map(m => Object.fromEntries([m]))

export default class Account {
    constructor(status) {
        this.enum = Symbol.for(status)
    }

    /**
     * 会員種別が選択されているかどうか
     * @returns {boolean}
     */
    get isSelectedMembership() {
        return this.enum === Symbol.for(ACCOUNT.MINI.description) ||
            this.enum === Symbol.for(ACCOUNT.STANDARD.description) ||
            this.enum === Symbol.for(ACCOUNT.PREMIUM.description)
    }

    /**
     * '無料会員'かどうか
     * @returns {boolean}
     */
    get isMini() {
        return this.enum === Symbol.for(ACCOUNT.MINI.description)
    }

    /**
     * '有料会員'かどうか
     * @returns {boolean}
     */
    get isStandard() {
        return this.enum === Symbol.for(ACCOUNT.STANDARD.description)
    }

    /**
     * '有料会員プラス'かどうか
     * @returns {boolean}
     */
    get isPremium() {
        return this.enum === Symbol.for(ACCOUNT.PREMIUM.description)
    }
}
export {ACCOUNT, AllAccountEnum};