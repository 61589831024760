import PuriSealsMachineViewModel from './PuriSealsMachineOutputData'
import PuriSealViewModel from './PuriSealOutputData'

/**
 * 指定されたシールIDに紐づくプリを取得するAPI(V2)のレスポンスに含まれるプリのentity.
 */
export default class PuriSealsOutputData {
    /**
     * @param data プリのentityを表すjsonフィールド群.
     */
    constructor(data) {
        this.sealId = data.sealId;                                                             // シールID
        this.sealExpireDate = data.sealExpireDate;                                     // シールの保存期限
        this.machine = new PuriSealsMachineViewModel(data.machine);                            // 筐体情報
        this.isProof = data.isProof;                                                           // 証明プリか
        this.puris = data.puris ? data.puris.map(puri => new PuriSealViewModel(puri)) : null;    // プリ画像情報リスト
    }
}