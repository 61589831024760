import pictlinkApi from '../interceptor/AxiosInterceptor'
import * as urlUtil from '../../util/UrlUtil'
import OptimizedFlowOutputData from "../output-data/OptimizedFlowOutputData";
import OptimizedFlowViewModel from "../../view-model/OptimizedFlowViewModel";

/**
 * ユーザ導線最適化で設定されたデータを取得するAPIを呼ぶ.
 * 対象データがない場合はnullを返す.
 * @param placeId
 */
export function getOptimizedFlow(placeId) {
    return pictlinkApi.get(urlUtil.apiEndpointUtil.optimizedFlowApiUrl(placeId))
        .then(result =>
            result ? new OptimizedFlowOutputData(result) : null
        ).then(result => {
            return result ? new OptimizedFlowViewModel(result) : null
        })
}