<template>
    <h3 class="m-heading-primary">{{ this.text }}</h3>
</template>

<script>
    export default {
        name: "heading-appeal-primary",
        props: {
            /**
             * 見出しにするテキスト
             */
            text: {
                type: String,
                required: true
            }
        }
    }
</script>
