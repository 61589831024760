import Membership from "./MembershipViewModel";
import AccountDetailViewModel from './AccountDetailViewModel'
import DateInterceptor from "../model/interceptor/DateInterceptor";

/**
 * アクセスユーザのシステム状態を表すview model.
 * (AccountViewModelのV2版)
 */
export default class AccountStatusViewModel {
    /**
     * @param data APIから受け取ったoutputData
     */
    constructor(data) {
        this.account = data.account ? new AccountDetailViewModel(data.account): null;
        this.membership = new Membership(data.membershipStatus);
        this.timeTripDate = new DateInterceptor(data.clock); // TimeTrip時刻を使用するときは必ずAccountStatusが呼ばれるので、AccountStatusに同梱することにした
        this.isTimeTripEnabled = Boolean(data.clock);
    }
}
